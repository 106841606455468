import $ from 'jquery';
import 'croppie';
import {saveAs} from 'file-saver';
import * as EXIF from 'exif-js';

$(document).on('click', '.js-avatar-popup', function () {
    $.magnificPopup.open({
        modal: true,
        items: {
            src: '/cruise-avatar.html',
            type: 'ajax'
        },
        callbacks: {
            ajaxContentAdded: initAvatarProcessor
        }
    });
    if (window.yaCounter36946060) {
        window.yaCounter36946060.reachGoal('avatarOpen');
    }
});

$(document).on('click', '.js-modal-close', function () {
    $.magnificPopup.close();
});

var $uploadCrop;
function initAvatarProcessor() {
    function readFile(input) {
        if (input.files && input.files[0]) {
            var reader = new FileReader();

            var orientation = 1;
            EXIF.getData(input.files[0], function () {
                if (this.exifdata.Orientation == 6) {
                    orientation = 6;
                }
            });

            reader.onload = function (e) {
                var image = new Image();
                image.onload = function(){
                    if (image.width < 600 || image.height < 600) {
                        alert("Your image is too small. Upload an image with size 600x600 px or more");
                    } else {
                        $uploadCrop.croppie('bind', {
                            url: e.target.result,
                            orientation: orientation
                        });
                    }
                };
                image.src = e.target.result;
            };
            reader.readAsDataURL(input.files[0]);
            return true;
        }
        else {
            alert("Sorry - you're browser doesn't support the FileReader API");
            return false;
        }
    }

    $uploadCrop = $('#upload').croppie({
        viewport: {
            width: 300,
            height: 300,
            type: 'square'
        },
        url: '/public/images/cruise/avatar/avatarGuide.jpg',
        showZoomer: true,
        enableExif: false,
        enableOrientation: true
    });


    $('.js-input-avatar').on('change', function (e) {
        var sBase64Img = readFile(this);
        if (sBase64Img) {
            $('#js-download-result').attr('avatar', 1);
        } else {
            $('#js-download-result').attr('avatar', 0);
        }
    });

    var $mainWindow = $('.avatar');

    $mainWindow.on('click', '.js-btn-upload-origin', function () {
        $('.js-input-avatar').trigger('click');
    });

    $mainWindow.on('click', '.js-mask', function () {
        var src = $(this).attr('src');
        if (src === $('.js-layout-mask').attr('src')) {
            $('.js-layout-mask').attr('src', '#');
            $('.js-layout-mask').hide();
            $('.js-layout-color').hide();
            $('#js-download-result').attr('mask', 0);
        } else {
            $('.js-layout-mask').attr('src', src);
            $('.js-layout-mask').show();
            $('.js-layout-color').show();
            $('#js-download-result').attr('mask', 1);
        }
    });


    $('#js-download-result').on('click', function (e) {
        e.preventDefault();
        if ($('#js-download-result').attr('avatar') == 0) {
            alert('Please upload the avatar');
            return false;
        }
        if ($('#js-download-result').attr('mask') == 0) {
            alert('Please select the mask');
            return false;
        }
        $uploadCrop.croppie('result', {
            type: 'rawcanvas',
            size: {width: 600, height: 600}
        }).then(function (result) {
            var canvas = result.getContext('2d');
            var mask = document.getElementById('avatar-mask');
            canvas.drawImage(mask, 0, 0);
            result.toBlob(function (blob) {
                saveAs(blob, "avatar.png");
            });
            if (window.yaCounter36946060) {
                window.yaCounter36946060.reachGoal('avatarDownload');
            }
        });
    });
};