<script>
    export default {
        name: 'rates-page',
        extends: require('../mixins/rates').default,
        methods: {
            getClass(code) {
                if (this.changes[code] > 0) {
                    return 'rates-list__cell--up';
                }
                if (this.changes[code] < 0) {
                    return 'rates-list__cell--down';
                }
                return null;
            },
        },
        filters: {
            formatChange(value) {
                return (value > 0 ? '+' : '') + value;
            }
        }
    }
</script>
<template>
    <table class="rates-list">
        <thead>
        <tr>
            <th class="rates-list__head">Pair</th>
            <th class="rates-list__head">Rate</th>
            <th class="rates-list__head">24h, %</th>
        </tr>
        </thead>
        <tbody>
        <tr class="rates-list__row" v-for="pair in pairs">
            <td class="rates-list__cell">
                {{pair.base_code}}/{{pair.quote_code}}
            </td>
            <td class="rates-list__cell">
                {{getRate(pair.pair_code) | currency(pair.base_code)}}
            </td>
            <td class="rates-list__cell" :class="getClass(pair.pair_code)">
                {{changes[pair.pair_code] | formatChange}}
            </td>
        </tr>
        </tbody>
    </table>
</template>