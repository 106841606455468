<script>
    export default {
        name: 'speaker-modal',
        props: ['modal'],
        data() {
            return {
                speaker: this.modal.speaker,
            };
        },
        methods: {
            close() {
                this.$parent.close();
            }
        }
    };
</script>
<template>
    <div class="popup-dialog zoom-anim-dialog">
        <div class="popup-dialog__title" v-html="speaker.first_name + ' ' + speaker.last_name"></div>
        <div class="popup-dialog__sub-title" v-html="speaker.company"></div>
        <div class="speaker-popup">
            <div class="speaker-popup__photo" v-if="speaker.photo_updated_at"
                 :style="{'background-image': 'url(/upload/cruise_participant/cruise_participant_' + speaker.photo_hash + ')'}"></div>
            <div class="speaker-popup__photo" v-else></div>
            <div class="speaker-popup__biography" v-if="speaker.about"
                 v-html="this.$options.filters['text-breaks'](speaker.about)"></div>
        </div>
        <div class="popup-dialog__footer popup-dialog__footer--bg-gray">
            <button type="button" class="btn btn-primary btn-primary_outline modal-close" @click.prevent="close">Close
            </button>
        </div>
    </div>
</template>