import Inputmask from 'inputmask';

export const InputMaskPlugin = {
    install: function (Vue) {
        Vue.directive('input-mask', {
            isLiteral: true,
            inserted: function (element, binding, vnode) {
                const options = vnode.data.attrs.options || {};
                options.onKeyDown = function () {
                    if ("createEvent" in document) {
                        const evt = document.createEvent("HTMLEvents");
                        evt.initEvent("change", false, true);
                        element.dispatchEvent(evt);
                    }
                    else {
                        element.fireEvent("onchange");
                    }
                };
                new Inputmask(binding.value, options).mask(element);
            }
        });
    }
};