export const cabins = [
    {id: 1, name: 'interior', capacityDefault: 2},
    {id: 2, name: 'window', capacityDefault: 2},
    {id: 3, name: 'balcony', capacityDefault: 2},
    {id: 4, name: 'junior', capacityDefault: 2},
    {id: 12, name: 'grand'},
    {id: 13, name: 'family'},
    {id: 14, name: 'owners'},
    {id: 16, name: 'gold'},
    {id: 15, name: 'royal'},
];

export const schemes = [
    {
        num: 2,
        name: 'deck-2',
        file: '/public/schemes/deck-2.svg'
    },
    {
        num: 3,
        name: 'deck-3',
        file: '/public/schemes/deck-3.svg'
    },
    {
        num: 4,
        name: 'deck-4',
        file: '/public/schemes/deck-4.svg'
    },
    {
        num: 7,
        name: 'deck-7',
        file: '/public/schemes/deck-7.svg'
    },
    {
        num: 8,
        name: 'deck-8',
        file: '/public/schemes/deck-8.svg'
    },
];