export default {
    template: '#basket-template',
    props: ['order'],
    computed: {
        amountTotal: function () {
            if (this.order.totalPrice) {
                return this.order.totalPrice;
            }
            return this.order.cabins.reduce(function (res, item) {
                return res + item.basePrice;
            }.bind(this), 0) + this.vipPassTotalPrice;
        },
        locationPrice: function () {
            return this.order.cabins.reduce(function (res, item) {
                return res + item.locationPrice;
            }, 0);
        },
        selectingPrice: function () {
            return this.order.cabins.reduce(function (res, item) {
                return res + item.selectByUserPrice;
            }, 0);
        },
        vipPassesCount: function () {
            return this.order.cabins.reduce(function (res, item) {
                return res + item.passengers.filter(function (passenger) {
                    return !!passenger.vipPass;
                }).length;
            }, 0);
        },
        vipPassTotalPrice: function (item) {
            return this.order.cabins.reduce(function (res, item) {
                if (item.vipPassTotalPrice) {
                    return res + item.vipPassTotalPrice;
                }
                return res + item.passengers.reduce(function (res, passenger) {
                    return res + (passenger.vipPass ? item.vipPassPrice : 0);
                }, 0);
            }, 0);
        },
    },
    data: function () {
        return {
            expanded: false,
        };
    }
}
