<script>
    import $ from 'jquery';
    export default {
        name: 'modal',
        props: ['component'],
        data() {
            return {
                data: {},
                opened: false,
            };
        },
        methods: {
            open(data = {}) {
                this.data = data;
                this.opened = true;
                $.magnificPopup.close();
                $.magnificPopup.open({
                    modal: true,
                    items: {
                        src: this.$el,
                        type: 'inline'
                    },
                    fixedContentPos: true,
                    fixedBgPos: true
                });
            },
            close() {
                $.magnificPopup.close();
                this.opened = false;
            }
        },
    }
</script>
<template>
    <div :is="component" v-if="opened" :modal="data"></div>
</template>