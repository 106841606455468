import {Session} from "../extra/session";

export default {
    data() {
        return {
            pairs: [],
            rates: [],
            changes: [],
            channel: null,
            session: new Session,
        };
    },
    created() {
        this.$apiRequest.get('/trade/pairs').then(result => {
            this.pairs = result.data.pairs;
            this.changes = result.data.ratesChanges;
        });

        this.start();
        this.session.observable.subscribe(() => {
            this.reset();
            this.start();
        })
    },
    methods: {
        getRate(code) {
            const rate = this.rates.find(rate => rate.code === code);
            return rate ? rate.exchrate : null;
        },
        start() {
            const params = {};
            if (this.session.token) {
                params.token = this.session.token;
            }
            this.channel = this.$socketChannels.channel('rates', params);
            this.channel.join();
            this.channel.on('data').subscribe(result => {
                this.rates = result.rates;
            });
            this.channel.on('new_data').subscribe(result => {
                const index = this.rates.findIndex(item => item.code === result.code);
                if (index > -1) {
                    this.rates[index].exchrate = result.exchrate;
                } else {
                    this.rates.push(result);
                }
            });
        },
        reset() {
            if (this.channel) {
                this.channel.leave();
            }
        }
    }
}
