export default {
    template: '#basket-template',
    props: ['order'],
    computed: {
        vipPassesCount: function () {
            return this.order.passengers.filter(function (passenger) {
                return !!passenger.vipPass;
            }).length;
        },
        vipPassTotalPrice: function () {
            return this.order.prices.vipPassPrice * this.vipPassesCount;
        },
    },
    data: function () {
        return {
            expanded: false,
        };
    }
}
