import {Vue} from '../vue';
import VueForm from 'vue-form';
import {cabins} from "../data/cruise";
import {LiveAgentSetup} from "../extra/live-agent";

const container = document.getElementById('cruise-upgrade');
if (container) {
    LiveAgentSetup();

    Vue.use(VueForm, {
        validators: {
            'phone-validator': require('../validators/phone').default,
            'backend-validator': require('../validators/backend').default,
        }
    });

    Vue.component('select-cabin-step', require('../components/cruise-upgrade/select-cabin').default);
    Vue.component('completed-step', require('../components/cruise-upgrade/completed').default);
    Vue.component('modal-about-cabin', require('../components/cruise-upgrade/modal/about-cabin').default);

    Vue.filter('price', require('../filters/price').default);
    Vue.filter('date', require('../filters/date').default);

    new Vue({
        el: container,
        data: {
            loaded: false,
            referral: window.referral || null,
            hash: window.hash || null,
            cabinTypes: [],
            aboutCabin: null,
            paymentDetails: null,
        },
        created() {
            this.getCabinTypes()
                .then(result => {
                    this.cabinTypes = result;
                });
        },
        methods: {
            getCabinTypes() {
                return this.$apiRequest.get('cruise/price', {
                    params: {
                        hash: this.hash
                    }
                })
                    .then(result => {
                        this.loaded = true;
                        return cabins
                            .map(cabin => {
                                const item = result.data.find(item => parseInt(item.id) === cabin.id);
                                if (item) {
                                    return Object.assign(item, cabin);
                                }
                                return null;
                            })
                            .filter(cabin => !!cabin);
                    });
            },
            complete(paymentDetails) {
                this.paymentDetails = paymentDetails;
            }
        },
    });
}