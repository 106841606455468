export default function (value, attrValue, vnode) {
    const errors = vnode.context.errors || [];
    const name = vnode.data.attrs.name;
    const error = errors.find(function (e) {
        return e.name === name && !e.showed;
    });
    if (error) {
        error.showed = true;
    }
    return !error;
}