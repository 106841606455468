import Vue from 'vue/dist/vue.common';
import VueForm from 'vue-form';
import $ from 'jquery';
import 'axios';

Vue.use(VueForm, {
    validators: {
        'phone-validator': function (value, attrValue, vnode) {
            return !!$(vnode.elm).intlTelInput("isValidNumber");
        },
        'backend-validator': require('./validators/backend').default,
    }
});

Vue.directive('intl-tel-input', require('./directives/intl-tel-input').default);
Vue.component('modal', require('./components/modal'));
// Vue.component('tickets', require('./components/tickets'));
// Vue.component('ticket-modal', require('./components/ticket-modal'));
// Vue.component('ticket-details-modal', require('./components/ticket-details-modal'));
// Vue.component('subscribe', require('./components/subscribe'));
// Vue.component('subscribed-modal', require('./components/subscribed-modal'));
// Vue.component('speakers', require('./components/speakers'));
// Vue.component('speaker-modal', require('./components/speaker-modal'));
// Vue.component('countdown', require('./components/coundown'));
// Vue.component('video-link', require('./components/video-link'));

new Vue({
    el: '[vue-app]',
});