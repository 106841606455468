import 'core-js/shim';

import Raven from 'raven-js';
const version = document.querySelector('meta[name=version]').getAttribute('content');
Raven
    .config('https://bd27592e061a4e91858bbd4ab902531c@sentry.coinsbank.com/3', {
        environment: window.location.href.includes('dev') ? 'development' : 'production',
        whitelistUrls: [
            /https?:\/\/(.*\.)?coinsbank\.com/,
            /https?:\/\/dev[0-9]\..*\.pvt/
        ]
    })
    .setRelease(version)
    .install();

import './preloader'
import './interface';

import './partials/articles';
import './partials/avatar';
import './partials/cardpay';
import './partials/cruise';
import './partials/cruise-booking';
import './partials/cruise-booking-promo';
import './partials/cruise-upgrade';
import './partials/faq';
import './partials/party-photo';
import './partials/paydoo';
import './partials/paydooEvent';
import './partials/support';
import './partials/toolbar';
import './partials/rates';
import './partials/countdown';
import './partials/cruise-auction';
import './partials/exhibition';
import './partials/events';
import './partials/speakers';
import './partials/schedule';
import './partials/cruise-photos';
import './partials/sharing';