import {Socket} from 'phoenix';
import {Observable} from "rxjs";

class Channel {
    constructor(socket, name, params) {
        this.name = name;
        this.instance = socket.channel(name, params);
    }

    join() {
        const joined = this.instance.join();
        return new Observable((observer) => {
            joined
                .receive('ok', resp => {
                    observer.next(resp);
                    observer.complete();
                })
                .receive('error', resp => {
                    observer.error(resp);
                })
                .receive('timeout', resp => {
                    observer.error(resp);
                });
        });
    }

    leave() {
        const leaved = this.instance.leave();
        return new Observable((observer) => {
            leaved
                .receive('ok', resp => {
                    observer.next(resp);
                    observer.complete();
                })
                .receive('error', resp => {
                    observer.error(resp);
                })
                .receive('timeout', resp => {
                    observer.error(resp);
                });
        });
    }

    on(message) {
        return new Observable((observer) => {
            this.instance.on(message, resp => {
                observer.next(resp);
            });
            this.instance.onClose(() => {
                observer.complete();
            });
        });
    }
}

export class SocketChannels {
    constructor(socketUrl) {
        this.socket = new Socket(socketUrl);
        this.socket.connect();
    }

    channel(topic, options = {}) {
        return new Channel(this.socket, topic, options);
    }
}
