import {Vue} from "../vue";
import moment from "moment";

const customEvents = [
    {
        speakers: [],
        start_time: "2018-09-10T11:00:00.000000+0200",
        end_time: null,
        title: null,
        description: null,
        content: '<p><strong>Blockchain cruise continues 3 more days/nights on the mysterious island of IBIZA with Futurama Blockchain Innovators Summit</strong> on the private villa in balearic forest &amp; amazing catamaran regatta.</p>' +
            '<p><a href="https://futurama.com"><img src="/images/banners/futurama/futurama_agenda.png" class="banner-cruise-futurama"></a></p>',
        location: {
            id: 999,
            name: "Futurama on the private villa",
            deck: null,
        },
        disabled: false,
        ordering: 0,
        image: null,
        image_caption: null,
        image_updated_at: null,
        day: {
            id: 7,
            name: "Conference day on Ibiza Island",
            day: "2018-09-10",
            timezone: "+0200"
        },
    }
];

const container = document.querySelector('[vue-schedule]');
if (container) {
    Vue.component('modal', require('../components/modal'));
    Vue.component('speaker-modal', require('../components/speaker-modal'));
    Vue.directive('schedule-slider', require('../directives/schedule-slider').default);
    Vue.filter('date', require('../filters/date').default);

    new Vue({
        el: container,
        data: {
            schedule: [],
            selectedDay: 0,
            all: false,
            loaded: false,
        },
        mounted() {
            this.update();
        },
        computed: {
            days() {
                return this.schedule
                    .filter(item => !!item.day)
                    .map(item => item.day)
                    .filter((item, index, result) => index === result.findIndex(day => day.id === item.id));
            },
        },
        methods: {
            update() {
                return this.$apiRequest.get('cruise/schedule')
                    .then(result => {
                        this.schedule = [...result.data, ...customEvents]
                            .filter(item => !item.disabled)
                            .sort((a, b) => moment(a.day.day).toDate() - moment(b.day.day).toDate())
                            .sort((a, b) => {
                                if (!a.start_time) {
                                    return 0;
                                }
                                return moment(a.start_time).toDate() - moment(b.start_time).toDate();
                            });
                        this.loaded = true;
                    });
            },
            getLocations(day) {
                return this.schedule
                    .filter(item => item.day.id === day.id)
                    .filter(item => !!item.location)
                    .map(item => item.location)
                    .filter((item, index, result) => index === result.findIndex(location => location.id === item.id));
            },
            getEvents(location, day) {
                return this.schedule
                    .filter(item => item.day.id === day.id)
                    .filter(item => !item.location || item.location.id === location.id);
            },
            speakerModal(speaker) {
                const modal = this.$refs['speaker-modal'];
                modal.open({
                    speaker: speaker
                });
            },
        },
    });
}