import {Vue} from "../vue";
import SocialSharing from 'vue-social-sharing';

const container = document.querySelector('[vue-sharing]');
if (container) {
    Vue.use(SocialSharing);

    new Vue({
        el: container,
    });
}