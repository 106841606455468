export function parseErrors(data, map = {}, name) {
    let result = [];
    for(let [num, value] of Object.entries(data)) {
        const key = map[num] || num;
        if (value) {
            if (typeof value === 'string') {
                result.push({
                    name: name,
                    value: value,
                });
            } else {
                result = result.concat(parseErrors(value, map, name ? name + '[' + key + ']' : key));
            }
        }
    }
    return result;
}