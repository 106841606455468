import moment from "moment";
import {parseErrors} from "../../extra/validation";

export default {
    template: '#select-cabin-step-template',
    props: ['hash', 'cabinTypes'],
    computed: {
        cabins() {
            return this.cabinTypes.filter(cabin => !cabin.soldOut);
        },
        cabinsUpgrade() {
            return this.cabins.filter(cabin => !!cabin.price);
        },
        cabinsAuction() {
            return this.cabins.filter(cabin => this.isAuction(cabin));
        },
    },
    methods: {
        isAuction(cabin) {
            return cabin.auction && cabin.auction.startDate;
        },
        isAuctionStarted(cabin) {
            const startDate = moment(cabin.auction.startDate).startOf('day').toDate();
            return cabin.auction.startDate && startDate < new Date();
        },
        select: function (cabin) {
            return this.$apiRequest.post('cruise/upgrade', {
                hash: this.hash,
                cabinType: cabin.id
            })
                .then(result => {
                    this.$emit('select', result.data);
                }, error => {
                    const response = error.response;
                    if (response.status === 412 && response.data.fields) {
                        const errors = parseErrors(response.data.fields);
                        alert(errors.map(error => error.value).join("\r\n"));
                    }
                    throw error;
                });
        },
        about: function (cabin) {
            this.$emit('about', cabin);
        },
    },
}
