import {Subject} from "rxjs";
import {getCookie} from "./cookies";

export class Session {
    constructor() {
        this.subject = new Subject();
        let authenticated = this.isAuthenticated;
        setInterval(() => {
            const currentState = this.isAuthenticated;
            if (currentState !== authenticated) {
                authenticated = currentState;
                this.subject.next(authenticated);
            }
        }, 500);
    }

    get observable() {
        return this.subject.asObservable();
    }

    get isAuthenticated() {
        return this.hasToken && !this.isTemporary;
    }

    get isAuthenticatedTemporary() {
        return this.hasToken && this.isTemporary;
    }

    get token() {
        return getCookie('cbauth');
    }

    get hasToken() {
        return !!this.token;
    }

    get isTemporary() {
        return !!getCookie('cbauth-temporary');
    }
}