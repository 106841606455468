import $ from 'jquery';
export default {
    inserted(element) {
        $(element).slick({
            variableWidth: true,
            centerMode: true,
            dots: false,
            arrows: true,
            infinite: false,
            speed: 300,
            slidesToShow: 1,
            adaptiveHeight: true,
            focusOnSelect: true
        });

        $(element).find('.slick-next').on('click', function () {
            agendaScrollPage();
        });

        $(document).find('.slick-prev').on('click', function () {
            agendaScrollPage();
        });
    },
    unbind(element) {
        $(element).slick('unslick');
    },
};

function agendaScrollPage() {
    $('html, body').animate({
        scrollTop: $('#agenda-anchor').offset().top - $('.header').height() - 80
    }, 400);
}