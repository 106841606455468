import moment from "moment";

export default {
    template: '#select-cabin-step-template',
    props: ['order', 'cabinTypes'],
    methods: {
        isAuctionStarted(cabin) {
            const startDate = moment(cabin.auction.startDate).startOf('day').toDate();
            return cabin.auction.startDate && startDate < new Date();
        },
        next: function () {
            this.$emit('next');
        },
        select: function (cabin) {
            this.$emit('select', cabin);
        },
        request: function (cabin) {
            this.$emit('request', cabin);
        },
        about: function (cabin) {
            this.$emit('about', cabin);
        },
    },
}
