import $ from 'jquery';

/* GALLERY */
if ($('.cruise-gallery').length) {
    var galleryCruiseDIV = $('.cruise-gallery__photos').fotorama({
            width: '100%',
            ratio: '820/540',
            arrows: false,
            nav: false,
            loop: true,
            margin: 0,
            fit: 'cover',
            click: false
        }).on('fotorama:showend', function () {
            galleryCount();
        }),
        galleryCruiseAPI = galleryCruiseDIV.data('fotorama');

    $('.cruise-gallery__nav_next').click(function () {
        galleryCruiseAPI.show('>');
    });

    $('.cruise-gallery__nav_prev').click(function () {
        galleryCruiseAPI.show('<');
    });

    var galleryCount = function () {
        $('.cruise-gallery__count').text((galleryCruiseAPI.activeIndex + 1) + '/' + galleryCruiseAPI.size);
    };

    $(document).ready(function () {

        galleryCount();
    });
}
