import {InputMaskPlugin} from "../plugins/inputmask";
import {Vue} from '../vue';
import VueForm from 'vue-form';

const container = document.getElementById('events');
if (container) {
    Vue.use(VueForm, {
        validators: {
            'phone-validator': require('../validators/phone').default,
            'backend-validator': require('../validators/backend').default,
        }
    });
    Vue.use(InputMaskPlugin);

    Vue.component('basket-block', require('../components/events/basket-block').default);
    Vue.component('select-ticket-step', require('../components/events/select-ticket').default);
    Vue.component('order-step', require('../components/events/order').default);
    Vue.component('completed-step', require('../components/events/completed').default);

    Vue.component('modal-add', require('../components/events/modal/add').default);

    Vue.directive('intl-tel-input', require('../directives/intl-tel-input').default);
    Vue.filter('price', require('../filters/price').default);

    new Vue({
        el: container,
        data: {
            loaded: false,
            step: 1,
            order: {
                totalPrice: null,
                tickets: []
            },
            referral: window.referral || null,
            eventId: window.eventId,
            promoCode: window.promoCode || null,
            ticketTypes: [],
            selectedTicket: null,
            paymentDetails: {},
        },
        created: function () {
            this.updateTickets();
        },
        methods: {
            nextStep: function () {
                this.step += 1;
                window.scrollTo(0, 0);
            },
            previousStep: function () {
                this.step -= 1;
                window.scrollTo(0, 0);
            },
            addTickets: function (items) {
                items.forEach(item => this.addTicket(item));
            },
            addTicket: function (item) {
                this.order.tickets.push(item);
            },
            createOrder: function (data) {
                data.promoCode = this.promoCode;
                data.referral = this.referral;
                data.eventId = this.eventId;
                data.tickets = this.order.tickets.map(function (item) {
                    return {
                        ticketId: item.ticket.id,
                        participants: item.participants
                    };
                });
                return this.$apiRequest.post('promoEvent/order', data)
                    .then(function (result) {
                        return result.data;
                    }.bind(this));
            },
            getTicketTypes: function () {
                return this.$apiRequest.get('promoEvent/ticket', {params: {
                    promoCode: this.promoCode,
                    eventId: this.eventId,
                }})
                    .then(function (result) {
                        return result.data;
                    });
            },
            updateTickets() {
                return this.getTicketTypes()
                    .then(function (result) {
                        this.loaded = true;
                        this.ticketTypes = result;
                        this.order.tickets.forEach(orderItem => {
                            orderItem.ticket = this.ticketTypes.find(item => item.id === orderItem.ticket.id);
                        });

                        const ticketId = window.selectedTicketId;
                        if (ticketId) {
                            const ticket = this.ticketTypes.find(item => item.id === parseInt(ticketId));
                            if (ticket) {
                                this.selectedTicket = ticket;
                            }
                        }
                        return result;
                    }.bind(this));
            },
            complete: function (paymentDetails) {
                this.paymentDetails = paymentDetails;
                this.nextStep();
            }
        },
    });
}