import {AirportsAutocompletePlugin} from "../plugins/airports";
import {InputMaskPlugin} from "../plugins/inputmask";
import {Vue} from '../vue';
import 'vue-grecaptcha';
import VueForm from 'vue-form';
import {LiveAgentSetup} from "../extra/live-agent";

const container = document.getElementById('cruise-booking-promo');
if (container) {
    LiveAgentSetup();

    Vue.use(VueForm, {
        validators: {
            'phone-validator': require('../validators/phone').default,
            'backend-validator': require('../validators/backend').default,
        }
    });
    Vue.use(AirportsAutocompletePlugin);
    Vue.use(InputMaskPlugin);

    Vue.use(window.VueGrecaptcha, {
        sitekey: window.sitekey
    });

    Vue.component('basket-block', require('../components/cruise-booking/basket-block').default);
    Vue.component('passengers-step', require('../components/cruise-booking-promo/passengers').default);
    Vue.component('completed-step', require('../components/cruise-booking-promo/completed').default);

    Vue.component('modal', require('../components/modal'));
    Vue.component('modal-about-vip', require('../components/cruise-booking/modal/about-vip').default);
    Vue.component('modal-checkout-ibiza', require('../components/cruise-booking/modal/checkout-ibiza').default);
    Vue.component('modal-terms', require('../components/cruise-booking/modal/terms').default);
    Vue.component('modal-privacy', require('../components/cruise-booking/modal/privacy').default);
    Vue.component('modal-accept', require('../components/cruise-booking-promo/modal-accept').default);

    Vue.directive('intl-tel-input', require('../directives/intl-tel-input').default);
    Vue.filter('price', require('../filters/price').default);
    Vue.filter('date', require('../filters/date').default);

    new Vue({
        el: container,
        data: {
            step: 1,
            order: {
                passengers: [{
                    firstName: null,
                    lastName: null,
                    birthDate: null,
                    citizenship: null,
                    airport: null,
                    vipPass: false,
                    flight: false,
                    checkoutIbiza: false,
                }],
                prices: {},
            },
            referral: window.referral || null,
            promoCode: window.promoCode || null,
            paymentDetails: null,
        },
        methods: {
            complete: function (paymentDetails) {
                this.paymentDetails = paymentDetails;
            }
        },
    });
}