import {parseErrors} from "../../extra/validation";

export default {
    template: '#confirmation-step-template',
    props: ['order'],
    data: function () {
        let name;
        if (this.order.cabins && this.order.cabins[0].passengers) {
            const passenger = this.order.cabins[0].passengers[0];
            name = passenger.firstName + ' ' + passenger.lastName;
        }

        return {
            promoCode: this.$root.promoCode,
            formState: {},
            cabinModal: null,
            termsModal: false,
            privacyModal: false,
            errors: [],
            agreed: false,
            contacts: {
                contactName: name,
                contactEmail: null,
                contactPhone: null,
                promoCode: null,
                assistanceNeeded: false,
            },
        };
    },
    computed: {
        isAvailable: function () {
            return this.order.cabins.find(function (item) {
                return !!item.price;
            });
        }
    },
    methods: {
        getDeck: function (number) {
            return parseInt(number).toString()[0];
        },
        selectCabin: function (item) {
            this.cabinModal = item;
        },
        getError: function (name) {
            var error = this.errors.find(function (error) {
                return error.name === name;
            });
            return error ? error.value : null;
        },
        submit: function () {
            const data = {
                contactName: this.contacts.contactName,
                contactEmail: this.contacts.contactEmail,
                contactPhone: this.contacts.contactPhone,
                assistanceNeeded: !!this.contacts.assistanceNeeded,
                referral: this.$root.referral
            };
            if (this.contacts.promoCode) {
                data.promoCode = this.contacts.promoCode;
            }
            return this.$root.confirmOrder(data)
                .then(function (result) {
                    this.$emit('complete', {
                        cryptoUrl: result.paymentByCryptoUrl,
                        cardUrl: result.paymentByCardUrl,
                    });
                    this.formState._reset();
                    if (window.yaCounter36946060) {
                        window.yaCounter36946060.reachGoal('bookingCheckout');
                    }
                    return result;
                }.bind(this), function (error) {
                    var response = error.response;
                    if (response.status === 412 && response.data.fields) {
                        this.errors = parseErrors(response.data.fields);
                        this.formState._validate();
                    }
                    throw error;
                }.bind(this));
        },
        cancel: function () {
            this.$emit('cancel');
        }
    }
}
