export default {
    template: '#select-ticket-step-template',
    props: ['order', 'ticketTypes'],
    data: function () {
        return {
            modalRequestTicket: null,
        };
    },
    methods: {
        next: function () {
            this.$emit('next');
        },
        select: function (ticket) {
            this.$emit('select', ticket);
        },
    },
}
