import Vue from "vue/dist/vue.common";
import {SocketChannels} from "./extra/channels";
import Axios from 'axios';

Vue.prototype.$socketBaseUrl = window.socketBaseUrl;
Vue.prototype.$socketChannels = new SocketChannels(Vue.prototype.$socketBaseUrl);

Vue.prototype.$apiBaseUrl = window.apiBaseUrl;
Vue.prototype.$apiRequest = Axios.create({
    baseURL: Vue.prototype.$apiBaseUrl,
    headers: {
        'Language': window.userLanguage || 'en',
    }
});

Vue.filter('currency', require('./filters/currency').default);

export {Vue};