import modalMixin from "../../../mixins/modal";

export default {
    template: '#modal-add-template',
    mixins: [modalMixin],
    props: ['ticket'],
    data: function () {
        return {
            count: 1,
        };
    },
    computed: {
        countValues: function () {
            return Array.apply(null, {length: 10})
                .map(function (value, index) {
                    return index + 1;
                });
        },
        totalPrice() {
            return (this.ticket.discountPrice || this.ticket.price) * this.count;
        }
    },
    methods: {
        cancel: function () {
            this.$emit('cancel');
        },
        getOrder: function () {
            const order = [];
            for (let ticketNum = 0; ticketNum < this.count; ticketNum++) {
                const participants = [];
                for (let participantNum = 0; participantNum < this.ticket.maxTicketsCount; participantNum++) {
                    participants.push({
                        firstName: null,
                        lastName: null,
                        email: null,
                        phone: null,
                        company: null,
                        companyPost: null,
                        url: null,
                    });
                }
                order.push({
                    ticket: this.ticket,
                    participants: participants,
                });
            }
            return order;
        },
        close: function () {
            this.$emit('close', this.getOrder());
        },
        next: function () {
            this.$emit('next', this.getOrder());
            if (window.yaCounter36946060) {
                window.yaCounter36946060.reachGoal('eventsAddBasket');
            }
        },
    }
}
