import modalMixin from "../../../mixins/modal";

export default {
    template: '#modal-about-vip',
    mixins: [modalMixin],
    props: ['passenger'],
    methods: {
        cancel: function () {
            this.$emit('close');
        },
        complete: function () {
            this.passenger.vipPass = true;
            this.$emit('close');
        },
    }
}
