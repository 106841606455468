import $ from 'jquery';

var share_click = function (a, type) {
    var url = window.location.href, domain, u, d = 'CoinsBank Blockchain Party 2016',
        c = 'CoinsBank - the bank of Blockchain future brought together key industry representatives in marvelous Belek';
    if (url.indexOf("://") > -1) domain = url.split('/')[2];
    else domain = url.split('/')[0];
    domain = domain.split(':')[0];
    u = 'https://' + domain + $(a).parents('figure').children('img').attr('src');

    if (type == 'fb') {
        FB.ui({
            method: 'share',
            display: 'popup',
            hashtag: '#coinsbank,#blockchainparty',
            href: $(location).attr('href'),
            link: 'https://coinsbank.com/party-result',
            picture: u,
            description: d,
            caption: c
        }, function (response) {
        });
    } else if (type == 'tw') {
        window.open('https://twitter.com/share?url=' + u + '&text=' + d + '&hashtags=coinsbank,blockchainparty', 'twitterwindow', 'height=450, width=550, top=' + ($(window).height() / 2 - 225) + ', left=' + $(window).width() / 2 + ', toolbar=0, location=0, menubar=0, directories=0, scrollbars=0');
    } else if (type == 'vk') {
        window.open('https://vk.com/share.php?url=' + encodeURIComponent('https://coinsbank.com/party-result') + '&title=' + encodeURIComponent(d) + '&image=' + encodeURIComponent(u) + '&description=' + encodeURIComponent(c), 'vkwindow', 'height=450, width=550, top=' + ($(window).height() / 2 - 225) + ', left=' + $(window).width() / 2 + ', toolbar=0, location=0, menubar=0, directories=0, scrollbars=0');
    }

    return false;
};


$(document).ready(function () {
    $('.party-gallery').magnificPopup({
        delegate: 'a',
        type: 'image',
        tLoading: 'Loading image #%curr%...',
        mainClass: 'mfp-img-mobile',
        gallery: {
            enabled: true,
            navigateByImgClick: true,
            preload: [0, 1] // Will preload 0 - before current, and 1 after the current image
        },
        image: {
            tError: '<a href="%url%">The image #%curr%</a> could not be loaded.',
            titleSrc: function () {
                return '<div class="share">' +
                    '<a href="#" class="share-button share-button_fb" onclick="return share_click(this,\'fb\')"><i class="icon-facebook"></i></a>' +
                    '<a href="#" class="share-button share-button_vk" onclick="return share_click(this,\'vk\')"><i class="icon-vk2"></i></a>' +
                    '<a href="#" class="share-button share-button_tw" onclick="return share_click(this,\'tw\')"><i class="icon-twitter"></i></a>' +
                    '</div>';
            }
        }
    });
});