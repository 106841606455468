import {Vue} from "../vue";

const container = document.querySelector('[vue-speakers]');
if (container) {
    Vue.component('modal', require('../components/modal'));
    Vue.component('speaker-modal', require('../components/speaker-modal'));
    Vue.directive('speaker-fade', require('../directives/speaker-fade').default);
    Vue.filter('text-breaks', require('../filters/text-breaks').default);

    new Vue({
        el: container,
        data: {
            speakers: [],
            loaded: false,
            all: false,
        },
        mounted() {
            this.update();
        },
        methods: {
            update() {
                return this.$apiRequest.get('cruise/speakers?sort=order')
                    .then(result => {
                        this.speakers = result.data
                            .filter(item => !item.disabled);
                        this.loaded = true;
                    });
            },
            showAll() {
                this.all = true;
            },
            speakerModal(speaker) {
                const modal = this.$refs['speaker-modal'];
                modal.open({
                    speaker: speaker
                });
            },
        }
    });
}