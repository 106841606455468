import modalMixin from "../../../mixins/modal";

export default {
    template: '#modal-add-template',
    mixins: [modalMixin],
    props: ['cabin'],
    data: function () {
        return {
            loaded: false,
            count: this.cabin.capacityMin,
            isAlone: true,
            prices: {
                cabin: null,
                vipPass: null,
            },
        };
    },
    created: function () {
        this.updatePrices();
    },
    computed: {
        countValues: function () {
            return Array.apply(null, {length: this.cabin.capacityMax})
                .map(function (value, index) {
                    return index + 1;
                });
        },
    },
    watch: {
        count: function () {
            this.updatePrices();
        },
        isAlone: function () {
            this.updatePrices();
        },
    },
    methods: {
        cancel: function () {
            this.$emit('cancel');
        },
        updatePrices: function () {
            return this.$root.getCabinPrice(this.cabin.id, this.count, (this.count === 1 && this.isAlone))
                .then(function (result) {
                    this.prices = {
                        cabin: parseFloat(result.price) || 0,
                        vipPass: parseFloat(result.vipPassPrice) || 0,
                        vipPassOnStock: !!result.vipPassOnStock,
                    };
                    this.loaded = true;
                    return this.prices;
                }.bind(this));
        },
        getOrder: function () {
            return this.updatePrices()
                .then(function () {
                    var passengers = [];
                    for (var i = 0; i < this.count; i++) {
                        passengers.push({
                            firstName: null,
                            lastName: null,
                            birthDate: null,
                            citizenship: null,
                            airport: null,
                            vipPass: false,
                            flight: false,
                            checkoutIbiza: false,
                        });
                    }
                    return {
                        cabin: this.cabin,
                        isAlone: this.count === 1 && this.isAlone,
                        basePrice: this.prices.cabin,
                        vipPassPrice: this.prices.vipPass,
                        vipPassOnStock: this.prices.vipPassOnStock,
                        passengers: passengers,
                    };
                }.bind(this));
        },
        close: function () {
            if (!this.disabled) {
                this.getOrder().then(function (order) {
                    this.$emit('close', order);
                }.bind(this));
            }
        },
        next: function () {
            if (!this.disabled) {
                this.getOrder().then(function (order) {
                    this.$emit('next', order);
                    if (window.yaCounter36946060) {
                        window.yaCounter36946060.reachGoal('bookingAddBasket');
                    }
                }.bind(this));
            }
        },
    }
}
