import {parseErrors} from "../../extra/validation";

export default {
    template: '#order-step-template',
    props: ['order'],
    data: function () {
        return {
            hasPromoCode: !!window.promoCode,
            referral: this.$root.referral,
            formState: {},
            loading: false,
            errors: [],
            contacts: {
                contactFirstName: null,
                contactLastName: null,
                contactEmail: null,
                contactPhone: null,
                whoReferred: null,
                promoCode: null,
            },
        };
    },
    mounted: function () {
        window.onbeforeunload = function (event) {
            if (this.formState.$dirty && [1, 2].includes(this.$root.step)) {
                return event.returnValue = 'There are unsaved changes';
            }
        }.bind(this);
    },
    methods: {
        removeTicket: function (item) {
            const index = this.order.tickets.findIndex(function (orderItem) {
                return orderItem === item;
            });
            if (index > -1) {
                this.order.tickets.splice(index, 1);
            }
            if (!this.order.tickets.length) {
                this.$emit('back');
            }
        },
        fieldName: function (keys) {
            return 'tickets[' + keys.join('][') + ']';
        },
        fieldState: function (keys) {
            return this.formState[this.fieldName(keys)];
        },
        hasFieldError: function (keys) {
            const state = this.fieldState(keys);
            return this.formState.$submitted && state && state.$invalid;
        },
        getError: function (name) {
            const error = this.errors.find(function (error) {
                return error.name === name;
            });
            return error ? error.value : null;
        },
        getErrorField: function (keys) {
            return this.getError(this.fieldName(keys));
        },
        applyPromoCode() {
            this.$root.promoCode = this.contacts.promoCode;
            this.$root.updateTickets();
        },
        isPromoCodeApplied() {
            return !!this.order.tickets.find(item => !!item.ticket.discountPrice);
        },
        isPromoCodeWrong() {
            return !!this.$root.promoCode && !this.isPromoCodeApplied();
        },
        submit: function () {
            this.loading = true;
            const data = {
                contactFirstName: this.contacts.contactFirstName,
                contactLastName: this.contacts.contactLastName,
                contactEmail: this.contacts.contactEmail,
                contactPhone: this.contacts.contactPhone,
                whoReferred: this.contacts.whoReferred,
            };
            return this.$root.createOrder(data)
                .then(result => {
                    this.$emit('complete', {
                        totalPrice: result.totalPrice,
                        cryptoUrl: result.paymentByCryptoUrl,
                        cardUrl: result.paymentByCardUrl,
                    });
                    this.formState._reset();
                    if (window.yaCounter36946060) {
                        window.yaCounter36946060.reachGoal('eventsCheckout');
                    }
                    return result;
                }, error => {
                    const response = error.response;
                    if (response.status === 412 && response.data.fields) {
                        this.errors = parseErrors(response.data.fields);
                        this.formState._validate();
                    }
                    throw error;
                });
        },
        back: function () {
            this.$emit('back');
        }
    }
}