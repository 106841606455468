import * as ScrollMagic from "scrollmagic";

export default {
    'inserted': function (element) {
        const controller = new ScrollMagic.Controller();
        new ScrollMagic.Scene({triggerElement: element.children[0], triggerHook: 0.85})
            .setClassToggle(element, 'b-speaker--fade')
            .addTo(controller);
    }
};
