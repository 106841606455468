import $ from 'jquery';
import 'fotorama/fotorama';
import modalMixin from "../../../mixins/modal";

export default {
    template: '#modal-about-cabin-template',
    mixins: [modalMixin],
    props: ['cabin'],
    mounted: function () {
        $('.fotorama').fotorama();
    },
    data: function () {
        return {};
    },
    methods: {
        close: function () {
            this.$emit('close');
        },
    }
}
