import {Vue} from "../vue";

const container = document.querySelector('[vue-toolbar]');
if (container) {
    new Vue({
        el: container,
        components: {
            'rates-toolbar': require('../components/rates-toolbar')
        }
    });
}