export default {
    mounted: function () {
        $.magnificPopup.open({
            modal: true,
            items: {
                src: this.$el,
                type: 'inline'
            },
            fixedContentPos: true,
            fixedBgPos: true
        });
    },
    beforeDestroy: function () {
        $.magnificPopup.close();
    }
};