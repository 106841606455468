let LiveAgentInitialized;
export function LiveAgentSetup() {
    if (LiveAgentInitialized) {
        return;
    }
    (function (d, src, c) {
        var t = d.scripts[d.scripts.length - 1], s = d.createElement('script');
        s.id = 'la_x2s6df8d';
        s.async = true;
        s.src = src;
        s.onload = s.onreadystatechange = function () {
            var rs = this.readyState;
            if (rs && (rs != 'complete') && (rs != 'loaded')) {
                return;
            }
            c(this);
        };
        t.parentElement.insertBefore(s, t.nextSibling);
    })(document, '/scripts/track.js',
        function (e) {
            try {
                if (typeof LiveAgent !== 'undefined') {
                    const isProduction = window.location.host.indexOf('coinsbank.com') === 0;
                    LiveAgent.createButton(isProduction ? 'baaa17f0' : '14620c02', e);
                    LiveAgentInitialized = true;
                }
            } catch (e) {
                console.warn(e);
            }
        });
}