export default {
    template: '#completed-step-template',
    props: ['order', 'payment'],
    methods: {
        cancel: function () {
            this.$emit('cancel');
        },
        pay() {
            if (window.yaCounter36946060) {
                window.yaCounter36946060.reachGoal('eventsSendOrder');
            }
        },
    },
}
