import {Vue} from "../vue";

const container = document.querySelector('[vue-rates]');
if (container) {
    new Vue({
        el: container,
        components: {
            'rates-page': require('../components/rates-page')
        }
    });
}