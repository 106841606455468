import {parseErrors} from "../../../extra/validation";
import modalMixin from "../../../mixins/modal";

export default {
    template: '#modal-request-cabin-template',
    mixins: [modalMixin],
    props: ['cabin'],
    data: function () {
        return {
            formState: {},
            errors: [],
            data: {
                firstName: null,
                lastName: null,
                email: null,
                phone: null,
                comment: null,
            },
            completed: false,
        };
    },
    methods: {
        getError(name) {
            const error = this.errors.find(function (error) {
                return error.name === name;
            });
            return error ? error.value : null;
        },
        submit() {
            return this.$root.requestCabin({
                cabinId: this.cabin.id,
                firstName: this.data.firstName,
                lastName: this.data.lastName,
                email: this.data.email,
                phone: this.data.phone,
                comment: this.data.comment
            })
                .then(result => {
                    this.completed = true;
                    this.formState._reset();
                    return result;
                }, error => {
                    const response = error.response;
                    if (response.status === 412 && response.data.fields) {
                        this.errors = parseErrors(response.data.fields);
                        this.formState._validate();
                    }
                    throw error;
                });
        },
        close() {
            this.$emit('close');
        }
    }
}
