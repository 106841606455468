export default function (amount, precision = null, strict = false) {
    let symbol = '';
    let count = 4;

    if (typeof amount === 'string') {
        if (amount[0] === '+') {
            symbol = amount[0];
        }
        amount = amount.replace(',', '.');
        amount = parseFloat(amount) || 0;
    }

    if (typeof precision === 'string') {
        switch (precision.toUpperCase()) {
            case 'BTC':
            case 'LTC':
            case 'XRP':
            case 'ETH':
            case 'DSH':
                count = 4;
                break;
            default:
                count = 2;
        }
    }

    if (typeof precision === 'number') {
        if (precision > 0 && precision < 1) { // If precision is pair's lot
            count = Math.abs(Math.floor(Math.log10(precision)));
        } else {
            count = precision;
        }
    }

    amount = amount || 0;
    const value = amount.toFixed(8).replace(/\.?0*$/, '').split('.');
    if (value[1] && value[1].length > count) {
        if (strict) {
            for (; count <= 8; count++) {
                const result = parseFloat(value[1].substr(0, count));
                if (result > 0) {
                    if (result.toString().length === 1) {
                        count++;
                    }
                    amount = parseFloat(value[0] + '.' + value[1].substr(0, count));
                    break;
                }
            }
        } else {
            count = value[1].length;
        }
    }

    return symbol + amount.toFixed(count);
}