import modalMixin from "../../../mixins/modal";

export default {
    template: '#modal-checkout-ibiza',
    mixins: [modalMixin],
    props: ['passenger'],
    methods: {
        cancel: function () {
            this.$emit('close');
        },
        complete: function () {
            this.passenger.checkoutIbiza = true;
            this.$emit('close');
        },
    }
}
