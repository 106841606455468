import 'jquery';
import 'jquery-ui/ui/widget';
import 'jquery-ui/ui/effect';
import 'fotorama/fotorama';
import 'magnific-popup';
import 'tooltipster/dist/js/tooltipster.bundle';
import './libs/jquery.pagescroller';
import './libs/modernizr';
import 'fullpage.js';
import 'bootstrap';
import jQuery from 'jquery';
import {getCookie, setCookie} from "./extra/cookies";
import {TweenLite, TweenMax} from "gsap";
import 'scrollmagic/scrollmagic/uncompressed/plugins/animation.gsap';
import * as ScrollMagic from 'scrollmagic';

Modernizr.prefixedCSSValue('display','flex');
var APP = APP || {};

(function($) {
    "use strict";

    $('#signup-form').on('submit', function (e) {
        e.preventDefault();
        var login = $(this).find('input[name=login]').val();
        var password = $(this).find('input[name=password]').val();
        setCookie('signup-login', login, {path: '/', expires: 60});
        setCookie('signup-password', password, {path: '/', expires: 60});
        window.location = $(this).attr('action');
    });

    if ($('body').hasClass('side-push-panel')) {
        $('.side-panel-trigger').click(function () {
            $('body.side-push-panel').toggleClass('side-push-panel_open');
            return false;
        });
    }

    $(document).on('click', function (event) {
        if ($(event.target).closest('.menu-side-close-btn').length) {
            $('body').toggleClass('side-push-panel_open', false);
        }
    });

    APP.initialize = {
        init: function() {
            APP.initialize.animations();
            APP.initialize.coverVideo();
            APP.initialize.fullScreen();
            APP.initialize.verticalMiddle();
            APP.initialize.accordionSetHeight();
            APP.initialize.lightbox();
            APP.initialize.banners();
            APP.initialize.slideGallery();
        },

        animations: function () {
            var $scrollCtrl = new ScrollMagic.Controller();

            $('.ticket').each(function(){
                var ticket_sc = new ScrollMagic.Scene({triggerElement: this.children[0], triggerHook: 0.85})
                    .setClassToggle(this, 'ticket--fade')
                    .addTo($scrollCtrl);
            });

            if (document.querySelector('.about-image')) {
                var about_img_tw = TweenLite.from('.about-image__img', 1, {y: -120});
                var about_img_sc = new ScrollMagic.Scene({
                    triggerElement: '.about-image',
                    duration: '100%',
                    triggerHook: 1
                })
                    .setTween(about_img_tw)
                    .addTo($scrollCtrl);
            }


            if (document.querySelector('.cruise-europe-map__map')) {
                var map_note_tw = TweenLite.from('.cruise-europe-map__note, .cruise-europe-map__social', 1, {y: 40});
                var map_note_sc = new ScrollMagic.Scene({
                    triggerElement: '.cruise-europe-map__map',
                    duration: '100%',
                    triggerHook: 1
                })
                    .setTween(map_note_tw)
                    .addTo($scrollCtrl);
            }

            if (document.querySelector('.past-events')) {
                var past_events_tw = TweenLite.from('.past-events__items', 1, {alpha: 0, y: 60});
                var past_events_sc = new ScrollMagic.Scene({
                    triggerElement: '.past-events',
                    duration: '200',
                    triggerHook: 0.85
                })
                    .setTween(past_events_tw)
                    .addTo($scrollCtrl);
            }

            if (document.querySelector('.agenda') && APP.isMobile.any()) {
                var preview_agenda_tw = TweenMax.fromTo('.agenda__day', 0.5, {x: '0'}, {
                    x: '-40%',
                    repeat: 1,
                    yoyo: true,
                    ease: Expo.easeOut
                });
                var preview_agenda = new ScrollMagic.Scene({triggerElement: '.agenda'})
                    .setTween(preview_agenda_tw)
                    .addTo($scrollCtrl);
            }

            if (document.querySelector('.triangle-cover')) {
                TweenMax.from('#ta_bg_left', 1.5, {alpha: 0, x: '-50%', ease: Power4.easeOut}).delay(1);
                TweenMax.from('#ta_bg_right', 1.5, {alpha: 0, x: '50%', ease: Power4.easeOut}).delay(1.3);
                TweenMax.from('#ta_blue', 1.5, {alpha: 0, x: '-50%', ease: Power4.easeOut}).delay(1.5);
                TweenMax.from('#ta_dotted_orange', 1.5, {alpha: 0, x: '-50%', ease: Power4.easeOut}).delay(1.8);
                TweenMax.from('#ta_dotted_blue', 1.5, {alpha: 0, x: '50%', ease: Power4.easeOut}).delay(1.8);
                TweenMax.from('#ta_photo', 1.5, {alpha: 0, x: 100, ease: Power4.easeOut}).delay(2);
                TweenMax.from('#ta_orange', 1.5, {alpha: 0, x: 30, ease: Power4.easeOut}).delay(2.2);
                TweenMax.from('#ta_dotted_white', 1.5, {alpha: 0, x: -30, ease: Power4.easeOut}).delay(2.4);
                TweenMax.from('#cruise_logo', 1.5, {alpha: 0, y: -30, ease: Power4.easeOut}).delay(2.2);
                TweenMax.from('#cruise_title', 1.5, {alpha: 0, y: 30, ease: Power4.easeOut}).delay(2.4);
            }
        },
        coverVideo: function () {
            if (!APP.isMobile.any()) {
                $('.cover-video').append('' +
                    '<video class="cover-video__video" autoplay loop muted>' +
                    '<source src="/images/cruise-europe/cover/DJI_0085.mp4" type="video/mp4"/>' +
                    '<source src="/images/cruise-europe/cover/DJI_0085.webm" type="video/webm"/>' +
                    '</video>' +
                    '<div class="cover-video__overlay"></div>' +
                    '<div class="cover-video__cover"></div>');
            } else {
                $('.cover-video').append('' +
                    '<div class="cover-video__cover"></div>');
            }
        },

        fullScreen: function() {
            var $fullScreenEl = $('.js-cover');
            if ($fullScreenEl.length > 0) {
                $fullScreenEl.each(function () {
                    var element = $(this),
                        scrHeight = $('body').height(),
                        headerHeightOff = $('header').outerHeight(),
                        topNavHeightOff = $('body').find('.cruise-europe-navigation').outerHeight();

                    topNavHeightOff = (topNavHeightOff) ? topNavHeightOff : 0;
                    scrHeight = scrHeight - headerHeightOff - topNavHeightOff;

                    if ($body.width() > 980) {
                        element.css('height', scrHeight);
                    } else {
                        element.css('height', 'auto');
                    }
                });
            }
        },

        stickyMenuCruise: function(topImageOffset) {
            if ($('.cruise-cover').length > 0) {
                var cruiseNav = $('.cruise-cover__menu');
                if ($(window).scrollTop() > topImageOffset) {
                    cruiseNav.addClass('cruise-cover__menu_stiky');
                } else {
                    cruiseNav.removeClass('cruise-cover__menu_stiky');
                }
            }

            if ($('.cruise-asia-cover').length > 0) {
                var cruiseNavAsia = $('.cruise-asia-cover__menu');
                if ($(window).scrollTop() > topImageOffset) {
                    cruiseNavAsia.addClass('cruise-asia-cover__menu_stiky');
                } else {
                    cruiseNavAsia.removeClass('cruise-asia-cover__menu_stiky');
                }
            }
        },

        verticalMiddle: function () {
            var $verticalMiddleEl = $('.js-vertical-middle'),
                headerHeightOff = $('.header').outerHeight();
            if ($verticalMiddleEl.length > 0) {
                $verticalMiddleEl.each(function () {
                    var element = $(this),
                        verticalMiddleH = element.outerHeight() - headerHeightOff;

                    element.css({
                        position: 'absolute',
                        top: '50%',
                        marginTop: -(verticalMiddleH / 2) + 'px'
                    });
                });
            }
        },

        accordionSetHeight: function () {
            var $setHeightEl = $('.accordion-list__link');
            if ($setHeightEl.length > 0) {
                $setHeightEl.each(function () {
                    var element = $(this),
                        elementH = element.innerHeight();

                    element.parent().css({
                        height: elementH
                    });
                })
            }
        },

        lightbox: function () {
            var $lightboxDialogEl = $('[data-lightbox="dialog"]'),
                $lightboxImageEl = $('[data-lightbox="image"]'),
                $lightboxGalleryEl = $('[data-lightbox="gallery"]'),
                $lightboxAjaxEl = $('[data-lightbox="ajax"]'),
                $lightboxIframeEl = $('[data-lightbox="iframe"]');

            if ($lightboxDialogEl.length > 0) {
                $lightboxDialogEl.magnificPopup({
                    type: 'inline',
                    fixedContentPos: false,
                    fixedBgPos: true,
                    overflowY: 'auto',
                    closeBtnInside: true,
                    preloader: false,
                    midClick: false,
                    removalDelay: 300,
                    mainClass: 'mfp-dialog'
                })
            }

            if ($lightboxImageEl.length > 0) {
                $lightboxImageEl.magnificPopup({
                    type: 'image',
                    closeOnContentClick: true,
                    closeBtnInside: false,
                    fixedContentPos: true,
                    mainClass: 'mfp-gallery',
                    image: {
                        verticalFit: true
                    }
                });
            }

            if ($lightboxGalleryEl.length > 0) {
                $lightboxGalleryEl.each(function () {
                    var element = $(this);

                    element.magnificPopup({
                        delegate: 'a[data-lightbox="gallery-item"]',
                        type: 'image',
                        closeOnContentClick: true,
                        closeBtnInside: false,
                        fixedContentPos: true,
                        mainClass: 'mfp-gallery', // class to remove default margin from left and right side
                        image: {
                            verticalFit: true
                        },
                        gallery: {
                            enabled: true,
                            navigateByImgClick: true,
                            preload: [0, 1] // Will preload 0 - before current, and 1 after the current image
                        }
                    });
                });
            }

            if ($lightboxAjaxEl.length > 0) {
                $lightboxAjaxEl.magnificPopup({
                    type: 'ajax',
                    alignTop: false,
                    overflowY: 'scroll', // as we know that popup content is tall we set scroll overflow by default to avoid jump
                    mainClass: 'mfp-popup',
                    showCloseBtn: false
                });
            }

            if ($lightboxIframeEl.length > 0) {
                $lightboxIframeEl.magnificPopup({
                    type: 'iframe',
                    alignTop: false,
                    overflowY: 'scroll', // as we know that popup content is tall we set scroll overflow by default to avoid jump
                    mainClass: 'mfp-popup',
                    showCloseBtn: true,
                    preloader: false
                });
            }
        },

        banners: function () {
            var $bannerEl = $('.banner');

            if ($bannerEl.length > 0) {
                $('.spacer-header-menu').addClass('spacer-header-menu--banner');
            }
        },

        slideGallery: function () {
            var galleryCruiseDIV = $('.js-cruise-gallery');
            if (galleryCruiseDIV.length > 0) {
                galleryCruiseDIV.each(function () {
                    var photos = $(this).find('.cruise-gallery__photos'),
                        navNext = $(this).find('.cruise-gallery__nav_next'),
                        navPrev = $(this).find('.cruise-gallery__nav_prev');

                    photos.fotorama({
                        width: '100%',
                        ratio: '820/540',
                        arrows: false,
                        nav: false,
                        loop: true,
                        margin: 0,
                        fit: 'cover',
                        click: false
                    }).on('fotorama:showend', function () {
                        $('.cruise-gallery__count').text((galleryCruiseAPI.activeIndex + 1) + '/' + galleryCruiseAPI.size);
                    });

                    navNext.click(function () {
                        galleryCruiseAPI.show('>');
                    });

                    navPrev.click(function () {
                        galleryCruiseAPI.show('<');
                    });

                    var galleryCruiseAPI = photos.data('fotorama');
                    $('.cruise-gallery__count').text((galleryCruiseAPI.activeIndex + 1) + '/' + galleryCruiseAPI.size);

                })
            }
        }
    };

    APP.plugins = {
        init: function(){
            APP.plugins.partnersCarousel();
            APP.plugins.pageScroller();
        },
        partnersCarousel: function () {
            var $partnersCarousel = $('.js-partners-carousel');
            if ($partnersCarousel.length > 0) {
                $partnersCarousel.each(function (e) {
                    $(this).addClass('js-partners-carousel_'+e);
                    $partnersCarousel = $('.js-partners-carousel_'+e);
                    $partnersCarousel.slick({
                        autoplay: true,
                        centerMode: false,
                        dots: false,
                        arrows: true,
                        infinite: true,
                        speed: 600,
                        slidesToShow: 5,
                        slidesToScroll: 5,
                        mobileFirst: false,
                        nextArrow: '<button class="b-partners__arrow b-partners__arrow--next"></button>',
                        prevArrow: '<button class="b-partners__arrow b-partners__arrow--prev"></button>',
                        responsive: [
                            {
                                breakpoint: 980,
                                settings: {
                                    slidesToShow: 4,
                                    slidesToScroll: 4,
                                    infinite: true
                                }
                            },
                            {
                                breakpoint: 720,
                                settings: {
                                    slidesToShow: 3,
                                    slidesToScroll: 3
                                }
                            },
                            {
                                breakpoint: 580,
                                settings: {
                                    slidesToShow: 2,
                                    slidesToScroll: 2
                                }
                            }
                        ]
                    })
                })

            }
        },
        pageScroller: function () {
            var $pagescroller = $('.js-pagescroller');
            if ($pagescroller.length > 0) {
                if ($pagescroller.find('.cruise-europe-navigation').length > 0) {

                    //for page euro cruise
                    $pagescroller.pageScroller({
                        navigation: '.page-scroll-nav',
                        sectionClass: 'page-scroll-section',
                        animationType: 'easeInOutExpo',
                        animationSpeed: 900,
                        scrollOffset: -165,
                        deepLink: true,
                        onChange: function() {
                            var section = this.currentSection;
                            $('.cruise-europe-navigation__link').each(function () {
                                $(this).removeClass('cruise-europe-navigation__link--active');
                                if ($(this).attr('rel') == section) {
                                    $(this).addClass('cruise-europe-navigation__link--active');
                                }
                            })
                        }
                    });
                } else {
                    //for cruise result page
                    $pagescroller.pageScroller({
                        navigation: '.page-scroll-nav',
                        sectionClass: 'page-scroll-section',
                        animationType: 'easeInOutExpo',
                        animationSpeed: 900,
                        scrollOffset: -165
                    });
                }

            }
        }
    };

    APP.isMobile =  {
        Android: function() {
            return navigator.userAgent.match(/Android/i);
        },
        BlackBerry: function() {
            return navigator.userAgent.match(/BlackBerry/i);
        },
        iOS: function() {
            return navigator.userAgent.match(/iPhone|iPad|iPod/i);
        },
        Opera: function() {
            return navigator.userAgent.match(/Opera Mini/i);
        },
        Windows: function() {
            return navigator.userAgent.match(/IEMobile/i);
        },
        any: function() {
            return (APP.isMobile.Android() || APP.isMobile.BlackBerry() || APP.isMobile.iOS() || APP.isMobile.Opera() || APP.isMobile.Windows());
        }
    };

    APP.documentOnLoad = {
        init: function () {
            var $preloader = $('.loaderArea'),
                $loader = $preloader.find('.loader');

            $loader.fadeOut();
            $preloader.delay(350).fadeOut('slow');
        }
    };

    APP.documentOnResize = {
        init: function(){
            var t = setTimeout( function(){
                APP.initialize.verticalMiddle();
                APP.initialize.accordionSetHeight();
                APP.initialize.fullScreen();
            }, 500 );
        }
    };

    APP.documentOnReady = {
        init: function() {
            APP.initialize.init();
            APP.plugins.init();
            APP.documentOnReady.windowscroll();
            var t = setTimeout( function(){
                APP.initialize.verticalMiddle();
            }, 500 );
        },
        windowscroll: function(){
            var topImageOffset = $('.js-cover').height() - 30;
            $window.on('scroll', function(){
                APP.initialize.stickyMenuCruise(topImageOffset);
            })
        }
    };

    var $window = $(window),
        $header = $('.header'),
        $body = $('body');
        // $content = $('.section'),
        // $verticalMiddleEl = $('.js__vertical-middle');

    $window.on( 'load', APP.documentOnLoad.init );
    $(document).ready( APP.documentOnReady.init );
    $window.on( 'resize', APP.documentOnResize.init );



})(jQuery);


$(document).ready(function() {
    $('#fullpage_js').fullpage({
        navigation: true,
        anchors: ['start', 'wallet', 'cards', 'exchange', 'merchant', 'mobile', 'footer'],
        scrollBar: true,
        sectionSelector: 'section',
        controlArrows: false,
        //fitToSection: false,
        //css3: false,
        normalScrollElements: '.menu-side',
        //scrollOverflow: false
        afterLoad: function () {
            $('section.footer').css('height','auto');
            $('section.footer > div').css('height','auto');
        }
    });

    var hovers = '.btn';
    $(document).on('mouseenter', hovers, function(){
        $(this).addClass('hover');
    });
    $(document).on('mouseleave', hovers, function(){
        $(this).removeClass('hover');
    });

    if (typeof VK != 'undefined') {
        var vk = VK.Share.button(false, {type: "round_nocount", text: "Share"});
        $('#vkshare').replaceWith(vk);
    }
});