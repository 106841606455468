import autocomplete from 'autocomplete.js';
import Fuse from 'fuse.js';
import airports from 'airport-data/airports.json';

export const AirportsAutocompletePlugin = {
    install: function (Vue) {
        const fuseAirports = new Fuse(airports.filter(item => !!item.iata), {
            shouldSort: true,
            threshold: 0.4,
            location: 0,
            distance: 100,
            maxPatternLength: 32,
            minPatternLength: 2,
            keys: [{
                name: 'iata',
                weight: 0.5
            }, {
                name: 'name',
                weight: 0.3
            }, {
                name: 'city',
                weight: 0.2
            }]
        });

        Vue.directive('airports-autocomplete', {
            inserted: function (element) {
                autocomplete(element, {
                    hint: false,
                    minLength: 2,
                    cssClasses: {
                        root: 'autocomplete',
                        prefix: 'autocomplete'
                    }
                }, [{
                    displayKey: item => item.iata,
                    source: (query, callback) => {
                        const result = fuseAirports.search(query) || [];
                        callback(result.slice(0, 10));
                    },
                    templates: {
                        suggestion: item => {
                            return item.iata + ' – ' + item.name + ' <br><small>' + item.city + ', ' + item.country + '</small>'
                        }
                    }
                }]);
                element.addEventListener('autocomplete:selected', function () {
                    const event = new Event('input', {bubbles: true});
                    element.dispatchEvent(event);
                });
            }
        });
    }
};