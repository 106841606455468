import $ from 'jquery';

$(document).ready(function () {
    var page = 1;
    $('.btn-more-loader').click(function () {
        var btn = $(this);
        if (btn.hasClass('loading')) {
            return false;
        }
        btn.addClass('loading');
        $.get('/' + btn.data('category'), {page: page}, function (data) {
            btn.removeClass('loading');
            if (!data.length) {
                $('.btn-more-block').hide();
            } else {
                page++;
                $('.blog').append(data);
                if (data.match(/.content__row/g).length < 5) {
                    $('.btn-more-block').hide();
                }
            }
        }).fail(function () {
            btn.prop('disabled', false);
            btn.removeClass('loading');
            $('.btn-more-block').hide();
        });
    });
});