import $ from 'jquery';

export default {
    inserted(element) {
        const photos = $(element).find('[photo-gallery-items]'),
            navNext = $(element).find('[photo-gallery-next]'),
            navPrev = $(element).find('[photo-gallery-prev]'),
            count = $(element).find('[photo-gallery-count]');

        const fotorama = photos.fotorama({
            width: '100%',
            ratio: '6/4',
            arrows: false,
            nav: false,
            loop: true,
            margin: 0,
            fit: 'cover',
            click: false
        });

        const galleryCruiseAPI = photos.data('fotorama');

        fotorama.on('fotorama:showend', () => {
            count.text((galleryCruiseAPI.activeIndex + 1) + '/' + galleryCruiseAPI.size);
        });

        navNext.click(function () {
            galleryCruiseAPI.show('>');
        });

        navPrev.click(function () {
            galleryCruiseAPI.show('<');
        });

        count.text((galleryCruiseAPI.activeIndex + 1) + '/' + galleryCruiseAPI.size);
    }
};