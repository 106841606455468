import $ from 'jquery';

window.CaptchaCallback = function () {
    grecaptcha.render('Recaptcha1', {
        'sitekey': window.reCaptchaKey,
        'callback': function () {
            $('.g-recaptcha').parent().find('.support-form__error').fadeOut();
        }
    });
};

$(document).ready(function () {
    $('.support-form__error').fadeOut();
    $('#support_form').find('input, select, textarea')
        .on('change keypress', function () {
            $(this).parent().removeClass('support-form__input_error');
            $(this).parent().find('.support-form__error').fadeOut();
        });

    $('#support_form').submit(function () {
        clearErrors();
        var data = {};
        $.each($(this).serializeArray(), function (_, v) {
            if (v.name == 'g-recaptcha-response') {
                data['reCaptchaCode'] = v.value.trim();
            } else {
                if (data.hasOwnProperty(v.name)) {
                    data[v.name] = $.makeArray(data[v.name]);
                    data[v.name].push(v.value.trim());
                } else {
                    data[v.name] = v.value.trim();
                }
            }
        });
        var hasErrors = false;
        $.each(data, function (k, v) {
            if (k != 'name' && v == '') {
                addError(k, 'Field cannot be blank');
                hasErrors = true;
            }
            if (k == 'email' && !validateEmail(v)) {
                addError(k, 'Email is invalid');
                hasErrors = true;
            }
            if (k == 'phoneNumber' && v != '') {
                data[k] = v.replace(/[^0-9.]/g, "");
                if (data[k].length < 9 || data[k].length > 14) {
                    addError(k, 'Phone Number is invalid');
                    hasErrors = true;
                }
            }
        });
        if (!hasErrors) {
            $('#support_form').addClass('loading');
            $('#support_form [type="submit"]').prop('disabled', true);
            $.post('/support.php', data, function (r) {
                grecaptcha.reset();
                $('#support_form [type="submit"]').prop('disabled', false);
                $('#support_form').removeClass('loading');
                if (r.result) {
                    $("#support_form")[0].reset();
                    grecaptcha.reset();

                    $.magnificPopup.open({
                        items: {
                            src: '<div class="popup-dialog zoom-anim-dialog">' +
                            '<div class="popup-dialog__icon popup-dialog__icon_success"></div>' +
                            '<div class="popup-dialog__title">Thank you!</div>' +
                            '<div class="popup-dialog__message">Our support team will contact you</div>' +
                            '</div>'
                        },
                        type: 'inline'
                    });
                } else {
                    $.each(r.errors, function (k, v) {
                        addError(k, v);
                    });
                }
            }, 'json').fail(function () {
                $('#support_form').removeClass('loading');
                grecaptcha.reset();
                addError('system', 'System error. Please try again later.');
            });
        }
        return false;
    });

    function addError(name, text) {
        if (name == 'system') {
            $('.support-form__error_global').html(text).fadeIn();
            return;
        }
        if (name == 'reCaptchaCode') {
            var obj = $('.g-recaptcha');
        } else {
            var obj = $('[name="' + name + '"]');
        }
        if (!obj.length) {
            return;
        }
        var parent = $(obj).parent();
        $(parent).addClass('support-form__input_error');
        $(parent).find('.support-form__error').html(text).fadeIn();
    }

    function clearErrors() {
        $('.support-form__input').removeClass('support-form__input_error');
        $('.support-form__error').fadeOut();
    }

    function validateEmail(email) {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    }
});