import {parseErrors} from "../../extra/validation";
import {countries} from "../../data/countries";

export default {
    template: '#passengers-step-template',
    props: ['order'],
    data() {
        return {
            captchaResponse: null,
            formState: {},
            loading: false,
            errors: [],
            modalVip: false,
            modalIbiza: false,
            termsModal: false,
            privacyModal: false,
            countries: countries,
            agreed: false,
            notAvailable: false,
            contacts: {
                contactName: null,
                contactEmail: null,
                contactPhone: null,
                assistanceNeeded: false,
            },
        };
    },
    mounted() {
        window.onbeforeunload = function (event) {
            if (this.formState.$dirty && [2].includes(this.$root.step)) {
                return event.returnValue = 'There are unsaved changes';
            }
        }.bind(this);

        this.updatePrices()
            .then(prices => this.acceptModal(prices));
    },
    methods: {
        acceptModal(prices) {
            const modal = this.$refs['modal-accept'];
            modal.open({
                prices: prices
            });
            return prices;
        },
        fieldName(keys) {
            const names = keys.slice();
            return names.shift() + (names.length ? '[' + names.join('][') + ']' : '');
        },
        fieldState(keys) {
            return this.formState[this.fieldName(keys)];
        },
        hasFieldError(name, key) {
            const state = this.fieldState(name, key);
            return this.formState.$submitted && state && state.$invalid;
        },
        getError(name) {
            const error = this.errors.find(function (error) {
                return error.name === name;
            });
            return error ? error.value : null;
        },
        getErrorField(keys) {
            return this.getError(this.fieldName(keys));
        },
        addPassenger() {
            this.order.passengers.push({
                firstName: null,
                lastName: null,
                birthDate: null,
                citizenship: null,
                airport: null,
                vipPass: false,
                flight: false,
                checkoutIbiza: false,
            });
            this.updatePrices();
        },
        removePassenger(index) {
            this.order.passengers.splice(index, 1);
            this.updatePrices();
        },
        updatePrices() {
            return this.$apiRequest.get('cruise/lastCabin', {
                params: {
                    paxCount: this.order.passengers.length,
                }
            })
                .then(result => {
                    this.order.prices = result.data;
                    return this.order.prices;
                }, error => {
                    this.notAvailable = true;
                    throw error;
                });
        },
        submit() {
            this.loading = true;
            const data = {
                passengers: this.order.passengers,
                gRecaptchaResponse: this.captchaResponse,
                referral: this.$root.referral,
                contactName: this.contacts.contactName,
                contactEmail: this.contacts.contactEmail,
                contactPhone: this.contacts.contactPhone,
            };
            return this.$apiRequest.post('cruise/lastCabin', data)
                .then(result => {
                    this.$emit('complete', result.data);
                    this.formState._reset();
                    if (window.yaCounter36946060) {
                        window.yaCounter36946060.reachGoal('bookingPromoAddPassangers');
                    }
                    return result.data;
                }, error => {
                    this.resetReCaptcha();
                    const response = error.response;
                    if (response.status === 412 && response.data.fields) {
                        this.errors = parseErrors(response.data.fields);
                        this.formState._validate();
                    }
                    throw error;
                });
        },
        resetReCaptcha() {
            this.$refs.recaptcha.reset();
        },
        back() {
            this.$emit('back');
        }
    }
}