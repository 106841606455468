export default {
    name: 'modal-accept',
    template: '#modal-accept-template',
    props: ['modal'],
    data() {
        return {
            prices: this.modal.prices,
            agreed: false,
        }
    },
    methods: {
        close() {
            this.$parent.close();
        }
    }
};