import modalMixin from "../../../mixins/modal";
import {schemes} from "../../../data/cruise";
import {parseErrors} from "../../../extra/validation";

export default {
    template: '#modal-select-cabin-template',
    mixins: [modalMixin],
    props: ['cabin'],
    data: function () {
        var selected = [];
        var defaultScheme = schemes[0];
        var number = parseInt(this.cabin.cabinNumber);
        if (number) {
            selected.push(number);
            var deck = parseInt(number.toString()[0]);
            var scheme = schemes.find(function (scheme) {
                return scheme.num === deck;
            });
            if (scheme) {
                defaultScheme = scheme;
            }
        }

        return {
            cabins: [],
            locations: [],
            selected: selected,
            schemes: schemes,
            deck: defaultScheme.name,
            selectCabinPrice: null,
            loaded: false,
            errors: [],
        };
    },
    computed: {
        available: function () {
            return this.cabins.map(function (cabin) {
                return cabin.number;
            });
        },
        locationCharge: function () {
            if (!this.isChanged) {
                return 0;
            }
            var prices = this.selected.map(function (number) {
                var cabin = this.cabins.find(function (item) {
                    return item.number === number;
                });
                var location = this.locations.find(function (item) {
                    return parseInt(item.id) === parseInt(cabin.location);
                });
                return location ? location.price : 0;
            }.bind(this));

            return prices.reduce(function (a, b) {
                return a + b;
            }, 0);
        },
        isChanged: function () {
            return this.cabin.selectedByUser || !this.selected.includes(this.cabin.cabinNumber);
        },
        extraCharge: function () {
            var result = this.locationCharge;
            if (this.isChanged) {
                result += this.selectCabinPrice;
            }
            return result;
        },
        disabled: function () {
            return !this.selected.length || this.cabin.cabinNumber === this.selected[0];
        }
    },
    created: function () {
        this.$root.getAvailableCabins(this.cabin.cabinNumber)
            .then(function (result) {
                this.loaded = true;
                this.locations = result.locations;
                this.selectCabinPrice = result.selectCabinPrice;
                this.cabins = result.cabins.map(function (cabin) {
                    cabin.number = parseInt(cabin.number);
                    return cabin;
                });
                if (this.cabin.cabinNumber) {
                    this.cabins.push({
                        number: this.cabin.cabinNumber,
                        location: this.cabin.location,
                    });
                }
            }.bind(this));
    },
    methods: {
        select: function (value) {
            this.selected = value;
        },
        cancel: function () {
            this.$emit('cancel');
        },
        confirm: function () {
            this.$root.changeCabin(this.cabin.cabinNumber, this.selected[0])
                .then(function () {
                    this.cabin.cabinNumber = this.selected[0];
                    this.$emit('complete');
                }.bind(this), function (error) {
                    var response = error.response;
                    if (response.status === 412 && response.data.fields) {
                        this.errors = parseErrors(response.data.fields);
                    }
                }.bind(this));
        },
    },
}
