import {parseErrors} from "../../extra/validation";
import {countries} from "../../data/countries";

export default {
    template: '#passengers-step-template',
    props: ['order'],
    data: function () {
        return {
            captchaResponse: null,
            formState: {},
            loading: false,
            errors: [],
            modalVip: null,
            modalIbiza: null,
            countries: countries,
        };
    },
    mounted: function () {
        window.onbeforeunload = function (event) {
            if (this.formState.$dirty && [1, 2].includes(this.$root.step)) {
                return event.returnValue = 'There are unsaved changes';
            }
        }.bind(this);
    },
    methods: {
        removeCabin: function (item) {
            var index = this.order.cabins.findIndex(function (orderItem) {
                return orderItem === item;
            });
            if (index > -1) {
                this.order.cabins.splice(index, 1);
            }
            if (!this.order.cabins.length) {
                this.$emit('back');
            }
        },
        fieldName: function (keys) {
            return 'cabins[' + keys.join('][') + ']';
        },
        fieldState: function (keys) {
            return this.formState[this.fieldName(keys)];
        },
        hasFieldError: function (name, key) {
            var state = this.fieldState(name, key);
            return this.formState.$submitted && state && state.$invalid;
        },
        getError: function (name) {
            var error = this.errors.find(function (error) {
                return error.name === name;
            });
            return error ? error.value : null;
        },
        getErrorField: function (keys) {
            return this.getError(this.fieldName(keys));
        },
        submit: function () {
            this.loading = true;
            return this.$root.createOrder(this.captchaResponse)
                .then(result => {
                    this.$emit('complete');
                    this.formState._reset();
                    if (window.yaCounter36946060) {
                        window.yaCounter36946060.reachGoal('bookingAddPassangers');
                    }
                    return result;
                }, error => {
                    this.resetRecaptcha();
                    const response = error.response;
                    if (response.status === 412 && response.data.fields) {
                        this.errors = parseErrors(response.data.fields);
                        this.formState._validate();
                    }
                    throw error;
                });
        },
        resetRecaptcha() {
            this.$refs.recaptcha.reset();
        },
        back: function () {
            this.$emit('back');
        }
    }
}