import {Vue} from "../vue";
import Axios from "axios";

const container = document.querySelector('[vue-cruise-photos]');
if (container) {
    Vue.directive('photo-gallery', require('../directives/photo-gallery').default);
    new Vue({
        el: container,
        data: {
            images: [],
            loaded: false,
        },
        mounted() {
            this.update();
        },
        methods: {
            update() {
                return Axios.get('/cruise-europe-photos.php')
                    .then(result => {
                        this.images = result.data.images;
                        this.loaded = true;
                    });
            },
        }
    });
}