import {AirportsAutocompletePlugin} from "../plugins/airports";
import {InputMaskPlugin} from "../plugins/inputmask";
import {Vue} from '../vue';
import 'vue-grecaptcha';
import VueForm from 'vue-form';
import {LiveAgentSetup} from "../extra/live-agent";

const container = document.getElementById('cruise-booking');
if (container) {
    LiveAgentSetup();

    Vue.use(VueForm, {
        validators: {
            'phone-validator': require('../validators/phone').default,
            'backend-validator': require('../validators/backend').default,
        }
    });
    Vue.use(AirportsAutocompletePlugin);
    Vue.use(InputMaskPlugin);

    Vue.use(window.VueGrecaptcha, {
        sitekey: window.sitekey
    });

    Vue.component('basket-block', require('../components/cruise-booking-random/basket-block').default);
    Vue.component('cabin-details-step', require('../components/cruise-booking-random/cabin-details').default);
    Vue.component('passengers-step', require('../components/cruise-booking-random/passengers').default);
    Vue.component('completed-step', require('../components/cruise-booking-random/completed').default);

    Vue.component('modal-about-vip', require('../components/cruise-booking/modal/about-vip').default);
    Vue.component('modal-checkout-ibiza', require('../components/cruise-booking/modal/checkout-ibiza').default);
    Vue.component('modal-terms', require('../components/cruise-booking/modal/terms').default);
    Vue.component('modal-privacy', require('../components/cruise-booking/modal/privacy').default);

    Vue.directive('intl-tel-input', require('../directives/intl-tel-input').default);
    Vue.filter('price', require('../filters/price').default);
    Vue.filter('date', require('../filters/date').default);

    new Vue({
        el: container,
        data: {
            step: 1,
            order: {},
            referral: window.referral || null,
            promoCode: window.promoCode || null,
        },
        methods: {
            setOrder(order) {
                this.order = order;
            },
            nextStep: function () {
                this.step += 1;
                window.scrollTo(0, 0);
            },
            previousStep: function () {
                this.step -= 1;
                window.scrollTo(0, 0);
            },
            complete: function (paymentDetails) {
                this.paymentDetails = paymentDetails;
                this.nextStep();
            }
        },
    });
}