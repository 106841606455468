import Vue from "vue/dist/vue.common";
import moment from 'moment';

const elements = document.querySelectorAll('[vue-countdown]');
if (elements.length) {
    elements.forEach(element => {
        new Vue({
            el: element,
            data() {
                return {
                    date: moment.utc('2018-09-01 17:00'),
                    duration: null,
                    interval: setInterval(() => this.update(), 1000),
                };
            },
            mounted() {
                this.update();
            },
            methods: {
                update() {
                    const diff = this.date.diff(new Date());
                    if (diff > 0) {
                        this.duration = moment.duration(diff);
                    } else {
                        this.duration = null;
                        clearInterval(this.interval);
                    }
                }
            },
            filters: {
                format(value) {
                    return parseInt(value) < 10 ? '0' + value : value;
                }
            }
        });
    });
}