import modalMixin from "../../../mixins/modal";

export default {
    template: '#modal-privacy',
    mixins: [modalMixin],
    methods: {
        close: function () {
            this.$emit('close');
        },
    }
}
