import $ from 'jquery';
import 'slick-carousel';
import 'intl-tel-input';
import 'intl-tel-input/build/js/utils';
import {LiveAgentSetup} from "../extra/live-agent";

if (document.querySelector('.content--cruise-europe')) {
    LiveAgentSetup();
}

/* CAROUSEL */
$('.js-carousel').slick({
    autoplay: true,
    dots: false,
    infinite: true,
    speed: 600,
    slidesToShow: 6,
    slidesToScroll: 6,
    responsive: [
        {breakpoint: 980, settings: {slidesToShow: 4, slidesToScroll: 4, infinite: true, dots: true}},
        {breakpoint: 720, settings: {slidesToShow: 3, slidesToScroll: 3}},
        {breakpoint: 580, settings: {slidesToShow: 2, slidesToScroll: 2}},
        {breakpoint: 400, settings: {slidesToShow: 1, slidesToScroll: 1}}
    ]
});

$('.js-carousel-flags').slick({
    autoplay: true,
    dots: false,
    infinite: true,
    speed: 600,
    slidesToShow: 6,
    slidesToScroll: 6,
    responsive: [
        {breakpoint: 980, settings: {slidesToShow: 5, slidesToScroll: 5, infinite: true, dots: true}},
        {breakpoint: 720, settings: {slidesToShow: 4, slidesToScroll: 4}},
        {breakpoint: 580, settings: {slidesToShow: 3, slidesToScroll: 3}},
        {breakpoint: 400, settings: {slidesToShow: 2, slidesToScroll: 2}}
    ]
});

$(document).ready(function () {

    let pr = '/cruise-order.php';

    //SUBSCRIBE SUBMIT
    $(document).on('click', '#btn-subscribe', function (e) {
        e.preventDefault();
        var data = {};
        data.email = $('.subscribe-form-europe__input').val();
        data.hash = $('.subscribe-form-europe__input').data('rel');
        $.post(pr + '?action=subscribe&sid=' + sid, data, function (response) {
            var r = $.parseJSON(response);
            var text;
            if (r.ok == 1) {
                text = $('#subscribe-template-success').html();
                $('.subscribe-form-europe__input').val('');
            } else {
                text = $('#subscribe-template-fail').html();
            }
            $.magnificPopup.open({items: {src: text, type: 'inline'}});
            if (window.yaCounter36946060) {
                window.yaCounter36946060.reachGoal('cruiseSubscribeNews');
            }
        });
        return false;
    });

    //FORM BECOME
    var recaptchaModal;
    $(document).on('click', '#sponsor_form, #partner_form, #speaker_form', function () {
        var $this = $(this);
        popupBecome($this, $this.text());
    });

    function popupBecome($this, title) {
        var content = $($('#become-template').html());
        content.find('.popup-dialog__title').text(title);
        content.find('form input').on('keypress', function () {
            $(this).removeClass('error');
            var next = $(this).next();
            if (next.is('.error-label')) {
                next.remove();
            }
        });
        content.find('form').on('submit', function (e) {
            e.preventDefault();
            var $this = $(this);
            var fields = {
                name: $this.parent().find('input[name="name"]'),
                phone: $this.parent().find('input[name="phone"]'),
                email: $this.parent().find('input[name="email"]'),
                company: $this.parent().find('input[name="company"]'),
            };
            var data = {
                cc: window.grecaptcha.getResponse(recaptchaModal),
                name: fields.name.val(),
                phone: fields.phone.val(),
                email: fields.email.val(),
                company: fields.company.val(),
                form: title,
            };

            $this.parent().find('input').removeClass('error');
            $this.parent().find('.error-label').remove();

            $.post(pr + '?action=become&sid=' + sid, data, function (response) {
                window.grecaptcha.reset();
                var result = $.parseJSON(response);
                var $div = $this.siblings('div');
                if (result && result.ok == 1) {
                    var resultContent = $($('#become-template-success').html());
                    resultContent.find('.popup-dialog__title').text(title);
                    $div.fadeOut(function () {
                        $.magnificPopup.close();
                        $.magnificPopup.open({
                            items: {
                                src: resultContent,
                                type: 'inline'
                            }
                        });
                    });
                } else if (result.errors) {
                    for (var name in result.errors) {
                        if (result.errors.hasOwnProperty(name)) {
                            var error = $('<div class="error-label">' + result.errors[name] + '</div>');
                            var field = fields[name];
                            if (field) {
                                field.addClass('error');
                                field.after(error);
                            } else {
                                $this.parent().find('.errors').append(error);
                            }
                        }
                    }
                } else {
                    $div.fadeOut(function () {
                        var resultContent = $($('#become-template-fail').html());
                        resultContent.find('.popup-dialog__title').text(title);
                        $.magnificPopup.close();
                        $.magnificPopup.open({
                            items: {
                                src: resultContent,
                                type: 'inline'
                            }
                        });
                    });
                }
            });
            return false;
        });
        content.find('input[name=phone]').intlTelInput({
            autoHideDialCode: false,
            nationalMode: false
        });

        $.magnificPopup.open({
            items: {
                src: content,
                type: 'inline'
            },
            callbacks: {
                open: function () {
                    recaptchaModal = window.grecaptcha.render('recaptcha-modal', {'sitekey': sitekey});
                }
            }
        });
    }
});

$(document).on('click', '.modal-close', function () {
    $.magnificPopup.close();
});