export default {
    template: '#basket-template',
    props: ['order'],
    computed: {
        amountTotal: function () {
            return this.order.tickets.reduce((res, item) => {
                return res + (item.ticket.discountPrice || item.ticket.price);
            }, 0);
        },
    },
    data: function () {
        return {
            expanded: false,
        };
    }
}
