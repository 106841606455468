export default {
    template: '#cabin-details-step-template',
    props: ['order'],
    data: function () {
        return {
            capacity: 4,
            passengersCount: this.order.passengers ? this.order.passengers.length : 1,
            vipPassesCount: this.order.passengers ? this.order.passengers.filter(pax => !!pax.vipPass).length : 0,
            upgraded: this.order && this.order.isUpgraded,
            prices: null,
        };
    },
    created() {
        this.updatePrices();
    },
    watch: {
        passengersCount: function () {
            this.syncVipPasses();
            this.updatePrices();
        },
        vipPassesCount: function () {
            this.syncVipPasses();
            this.updatePrices();
        },
        upgraded: function () {
            this.updatePrices();
        },
    },
    methods: {
        syncVipPasses() {
            if (this.vipPassesCount > this.passengersCount) {
                this.vipPassesCount = this.passengersCount;
            }
        },
        updatePrices: function () {
            return this.$apiRequest.get('cruise/price/options', {
                params: {
                    paxCount: this.passengersCount,
                    vipPassCount: this.vipPassesCount,
                    isUpgraded: this.upgraded,
                }
            })
                .then(function (result) {
                    this.prices = result.data;
                    this.loaded = true;
                    return this.prices;
                }.bind(this));
        },
        getOrder: function () {
            return this.updatePrices()
                .then(function () {
                    let passengers = [];
                    for (let i = 0; i < this.passengersCount; i++) {
                        passengers.push({
                            firstName: null,
                            lastName: null,
                            birthDate: null,
                            citizenship: null,
                            airport: null,
                            vipPass: i < this.vipPassesCount,
                            flight: false,
                            checkoutIbiza: false,
                        });
                    }

                    return {
                        prices: this.prices,
                        isUpgraded: this.upgraded,
                        cabinType: this.upgraded ? this.prices.baseUpgrade.cabinId : this.prices.baseCabinId,
                        passengers: passengers,
                    };
                }.bind(this));
        },
        passenger(count) {
            const result = this.passengersCount + count;
            if (result >= 1 && result <= this.capacity) {
                this.passengersCount = result;
            }
        },
        vipPass(count) {
            const result = this.vipPassesCount + count;
            if (result >= 0 && result <= this.capacity) {
                this.vipPassesCount = result;
            }
        },
        next: function () {
            this.getOrder().then(function (order) {
                this.$emit('next', order);
                if (window.yaCounter36946060) {
                    window.yaCounter36946060.reachGoal('bookingAddBasket');
                }
            }.bind(this));
        },
    },
}
