import {AirportsAutocompletePlugin} from "../plugins/airports";
import {InputMaskPlugin} from "../plugins/inputmask";
import {Vue} from '../vue';
import 'vue-grecaptcha';
import VueForm from 'vue-form';
import 'scheme/index';
import {deleteCookie, getCookie, setCookie} from "../extra/cookies";
import {schemes, cabins} from "../data/cruise";

const container = document.getElementById('cruise-booking');
if (container) {
    Vue.use(VueForm, {
        validators: {
            'phone-validator': require('../validators/phone').default,
            'backend-validator': require('../validators/backend').default,
        }
    });
    Vue.use(AirportsAutocompletePlugin);
    Vue.use(InputMaskPlugin);
    Vue.use(window.SchemesPlugin, {
        schemes: schemes
    });

    Vue.use(window.VueGrecaptcha, {
        sitekey: window.sitekey
    });

    Vue.component('basket-block', require('../components/cruise-booking/basket-block').default);
    Vue.component('select-cabin-step', require('../components/cruise-booking/select-cabin').default);
    Vue.component('passengers-step', require('../components/cruise-booking/passengers').default);
    Vue.component('confirmation-step', require('../components/cruise-booking/confirmation').default);
    Vue.component('completed-step', require('../components/cruise-booking/completed').default);

    Vue.component('modal-add', require('../components/cruise-booking/modal/add').default);
    Vue.component('modal-about-cabin', require('../components/cruise-booking/modal/about-cabin').default);
    Vue.component('modal-about-vip', require('../components/cruise-booking/modal/about-vip').default);
    Vue.component('modal-checkout-ibiza', require('../components/cruise-booking/modal/checkout-ibiza').default);
    Vue.component('modal-select-cabin', require('../components/cruise-booking/modal/select-cabin').default);
    Vue.component('modal-terms', require('../components/cruise-booking/modal/terms').default);
    Vue.component('modal-privacy', require('../components/cruise-booking/modal/privacy').default);
    Vue.component('modal-request-cabin', require('../components/cruise-booking/modal/request-cabin').default);

    Vue.directive('intl-tel-input', require('../directives/intl-tel-input').default);
    Vue.filter('price', require('../filters/price').default);
    Vue.filter('date', require('../filters/date').default);

    new Vue({
        el: container,
        data: {
            loaded: false,
            step: 1,
            order: {
                totalPrice: null,
                cabins: []
            },
            referral: window.referral || null,
            promoCode: window.promoCode || null,
            cabinTypes: [],
            selectedCabin: null,
            requestedCabin: null,
            aboutCabin: null,
            paymentDetails: {},

            expiry: null,
            sessionTime: 0,
            sessionInterval: 0,
            hash: getCookie('booking-hash') || null,
        },
        created: function () {
            this.getCabinTypes()
                .then(function (result) {
                    this.cabinTypes = result;
                    this.updateOrder()
                        .then(function () {
                            this.loaded = true;
                            this.step = 3;
                        }.bind(this), function () {
                            this.loaded = true;
                        }.bind(this));
                    const cabinId = window.selectedCabinId;
                    if (cabinId) {
                        const cabin = this.cabinTypes.find(item => item.id === parseInt(cabinId));
                        if (cabin) {
                            this.selectedCabin = cabin;
                        }
                    }
                }.bind(this));
        },
        methods: {
            nextStep: function () {
                this.step += 1;
                window.scrollTo(0, 0);
            },
            previousStep: function () {
                this.step -= 1;
                window.scrollTo(0, 0);
            },
            addCabin: function (item) {
                this.order.cabins.push(item);
            },
            setHash: function (value) {
                if (value) {
                    setCookie('booking-hash', value, {path: '/'});
                } else {
                    deleteCookie('booking-hash');
                }
                this.hash = value;
            },
            startSession: function (value) {
                clearInterval(this.sessionInterval);
                if (value) {
                    this.sessionInterval = setInterval(function () {
                        this.sessionTime -= 1;
                        if (this.sessionTime < 1) {
                            this.closeSession();
                            this.updateOrder();
                        }
                    }.bind(this), 1000);
                }
                this.sessionTime = value;
            },
            closeSession: function () {
                this.sessionTime = 0;
                clearInterval(this.sessionInterval);
            },
            processOrder: function (result) {
                this.setHash(result.hash);
                if (result.sessionTime > 0) {
                    this.startSession(result.sessionTime);
                }
                this.expiry = result.expiry;

                if (result.cabins) {
                    this.order.totalPrice = result.totalPrice;
                    this.order.promoDiscount = parseInt(result.promoDiscount);
                    this.order.cabins = result.cabins
                        .map(function (cabin) {
                            var currentCabin = this.cabinTypes.find(function (item) {
                                return item.id === cabin.orderInfo.cabinType;
                            });
                            return {
                                cabin: currentCabin,
                                cabinNumber: parseInt(cabin.cabinNumber),
                                location: parseInt(cabin.location),
                                price: parseFloat(cabin.price),
                                basePrice: parseFloat(cabin.basePrice),
                                locationPrice: parseFloat(cabin.locationPrice),
                                vipPassTotalPrice: parseFloat(cabin.vipPassTotalPrice),
                                priceWithVipPasses: parseFloat(cabin.priceWithVipPasses),
                                selectByUserPrice: parseFloat(cabin.selectByUserPrice),
                                selectedByUser: cabin.selectedByUser,
                                cabinType: parseInt(cabin.orderInfo.cabinType),
                                isAlone: cabin.orderInfo.isAlone,
                                passengers: cabin.orderInfo.passengers,
                            };
                        }.bind(this))
                        .filter(function (cabin) {
                            return !!cabin.cabin;
                        });
                }
            },
            createOrder: function (captchaResponse) {
                var data = {
                    gRecaptchaResponse: captchaResponse,
                    promoCode: this.promoCode,
                    referral: this.referral,
                    cabins: this.order.cabins.map(function (item) {
                        return {
                            cabinType: item.cabin.id,
                            isAlone: !!item.isAlone,
                            passengers: item.passengers,
                        };
                    })
                };
                return this.$apiRequest.post('cruise/order', data)
                    .then(function (result) {
                        this.processOrder(result.data);
                        return result.data;
                    }.bind(this));
            },
            updateOrder: function () {
                if (this.hash) {
                    return this.$apiRequest.get('cruise/order/_' + this.hash)
                        .then(function (result) {
                            this.processOrder(result.data);
                            return result.data;
                        }.bind(this), function (error) {
                            this.resetOrder();
                            throw error;
                        }.bind(this));
                }
                return window.Promise.reject();
            },
            resetOrder: function () {
                this.order = {
                    totalPrice: null,
                    cabins: []
                };
                this.setHash(null);
                this.closeSession();
                this.paymentDetails = {};
                this.step = 1;
            },
            cancelOrder: function () {
                if (this.hash) {
                    this.$apiRequest.delete('cruise/order/_' + this.hash);
                }
                this.resetOrder();
            },
            confirmOrder: function (data) {
                return this.$apiRequest.put('cruise/order/_' + this.hash, data)
                    .then(function (result) {
                        return result.data;
                    });
            },
            requestCabin: function (data) {
                return this.$apiRequest.post('cruise/requestCabin', data)
                    .then(function (result) {
                        return result.data;
                    });
            },
            getCabinTypes: function () {
                return this.$apiRequest.get('cruise/price', {
                    params: {
                        promoCode: this.promoCode,
                    }
                })
                    .then(function (result) {
                        return cabins
                            .map(function (cabin) {
                                var item = result.data.find(function (item) {
                                    return parseInt(item.id) === cabin.id;
                                });
                                if (item) {
                                    return Object.assign(item, cabin);
                                }
                                return null;
                            })
                            .filter(function (cabin) {
                                return !!cabin;
                            });
                    });
            },
            getAvailableCabins: function (number) {
                return this.$apiRequest.get('cruise/order/cabin/_' + this.hash, {
                    params: {
                        cabinNumber: number
                    }
                }).then(function (result) {
                    return result.data;
                });
            },
            changeCabin: function (oldNumber, newNumber) {
                return this.$apiRequest.put('/cruise/order/cabin/_' + this.hash, {
                    oldCabinNumber: oldNumber,
                    newCabinNumber: newNumber,
                })
                    .then(function () {
                        this.updateOrder();
                    }.bind(this));
            },
            getCabinPrice: function (cabinId, passengersCount, isAlone) {
                return this.$apiRequest.get('cruise/price/_' + cabinId, {
                    params: {
                        passengersCount: passengersCount,
                        promoCode: this.promoCode,
                    }
                }).then(function (result) {
                    return result.data;
                });
            },
            complete: function (paymentDetails) {
                this.paymentDetails = paymentDetails;
                this.nextStep();
            }
        },
    });
}