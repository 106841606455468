import {InputMaskPlugin} from "../plugins/inputmask";
import Vue from 'vue/dist/vue.common';
import VueForm from 'vue-form';
import Axios from 'axios';
import {getCookie} from "../extra/cookies";
import moment from "moment/moment";
import {parseErrors} from "../extra/validation";
import CircleSlider from '../components/circle-slider';
import {cabins} from "../data/cruise";
import {LiveAgentSetup} from "../extra/live-agent";

const container = document.getElementById('cruise-auction');
if (container) {
    LiveAgentSetup();

    Vue.use(VueForm, {
        validators: {
            'phone-validator': require('../validators/phone').default,
            'backend-validator': require('../validators/backend').default,
        }
    });
    Vue.use(InputMaskPlugin);

    Vue.filter('price', require('../filters/price').default);
    Vue.filter('date', require('../filters/date').default);
    Vue.directive('intl-tel-input', require('../directives/intl-tel-input').default);

    let headers = {
        'Language': window.userLanguage || 'en',
    };

    const authToken = getCookie('cbauth') || null;
    if (authToken) {
        headers['Authtoken'] = authToken;
    }
    const request = Axios.create({
        baseURL: window.apiBaseUrl,
        headers: headers,
    });

    new Vue({
        el: container,
        data: {
            data: null,
            cabin: cabins.find(cabin => cabin.id === window.cabinId) || {},
            cabinId: window.cabinId,
            hash: window.betHash || null,
            orderHash: window.orderHash || null,
            formState: {},
            errors: [],
            price: null,
            timer: {
                duration: null,
                interval: null,
            },
            success: false,
            isNotAvailable: false,
            payment: null,
        },
        created() {
            this.update();
        },
        computed: {
            percent() {
                if (this.data) {
                    const min = this.data.auction.minPrice;
                    const max = this.data.auction.maxPrice;
                    return parseInt(((this.price - min) * 100) / (max - min))
                }
                return 0;
            },
            chance() {
                if (this.percent > 66) {
                    return 3;
                }
                if (this.percent > 33) {
                    return 2;
                }
                return 1;
            }
        },
        methods: {
            authRedirect() {
                window.location = '/app/login?backUrl=/cruise-europe-auction?cabin=' + this.cabinId;
            },
            update() {
                request.get('cruise/auction/_' + this.cabinId, {
                    params: {
                        hash: this.hash,
                        orderHash: this.orderHash,
                    }
                })
                    .then(result => {
                        this.data = result.data;
                        this.cabin = Object.assign(this.data.cabinType, this.cabin);
                        this.data.cabin = this.cabin;
                        this.priceStart = this.data.price || null;
                        this.price = this.data.price || (parseInt(this.data.auction.minPrice + this.data.auction.maxPrice) / 2);
                        this.startTimer();
                    }, error => {
                        if (error.response && error.response.status === 403) {
                            this.authRedirect();
                        }
                        this.isNotAvailable = true;
                    });
            },
            startTimer() {
                clearInterval(this.timer.interval);
                this.updateDuration();
                this.timer.interval = setInterval(() => this.updateDuration(), 1000);
            },
            updateDuration() {
                if (this.data.auction.endDate) {
                    const diff = moment(this.data.auction.endDate).endOf('day').diff(new Date());
                    if (diff > 0) {
                        this.timer.duration = moment.duration(diff);
                        return;
                    }
                }
                this.timer.duration = null;
                clearInterval(this.timer.interval);
            },
            getError: function (name) {
                const error = this.errors.find(function (error) {
                    return error.name === name;
                });
                return error ? error.value : null;
            },
            isNotOpened() {
                return this.data && this.data.auction.startDate && new moment(this.data.auction.startDate).startOf('day').toDate() > new Date();
            },
            isClosed() {
                return this.data && (this.data.auction.closed || !this.data.auction.endDate || moment(this.data.auction.endDate).endOf('day').toDate() < new Date());
            },
            submit: function () {
                const data = {
                    cabin_type_id: cabinId,
                    price: this.price,
                    hash: this.hash,
                    orderHash: this.orderHash,
                };
                return request.post('cruise/auction', data)
                    .then(result => {
                        this.success = true;
                        this.formState._reset();
                        return result;
                    }, error => {
                        const response = error.response;
                        if (response.status === 412 && response.data.fields) {
                            this.errors = parseErrors(response.data.fields);
                            this.formState._validate();
                        }
                        throw error;
                    });
            },
            pay() {
                const data = {
                    hash: this.hash,
                };
                return request.post('cruise/auction/pay', data)
                    .then(result => {
                        this.payment = result.data;
                        return result;
                    }, error => {
                        const response = error.response;
                        if (response.status === 412 && response.data.fields) {
                            this.errors = parseErrors(response.data.fields);
                            this.formState._validate();
                        }
                        throw error;
                    });
            }
        },
        filters: {
            number(value) {
                return parseInt(value) < 10 ? '0' + value : value;
            }
        },
        components: {
            CircleSlider,
        }
    });
}