import Vue from 'vue/dist/vue.common';

Vue.component('modal-exhibition', require('../components/cruise-landing/modal/exhibition').default);

const element = document.querySelector('[vue-exhibition]');
if (element) {
    new Vue({
        el: element,
        data: {
            modalExhibition: false
        },
    });
}