<script>
    import CircleSlider from "circle-slider";

    export default {
        name: 'circle-slider',
        props: {
            value: [String, Number],
            start: {
                type: [String, Number],
                default: 0
            },
            min: {
                type: [String, Number],
                default: 0
            },
            max: {
                type: [String, Number],
                default: 100
            },
        },
        data() {
            return {
                data: {
                    slider: null,
                },
            };
        },
        methods: {
            angleChanged(angle) {
                const percent = parseInt(angle * 100 / 300);
                const value = parseInt((percent * (this.max - this.min) / 100) + this.min);
                this.$emit('input', value);
            },
            getDefaultAngle() {
                return this.calculateAngle(this.value);
            },
            getMinAngle() {
                if (this.start && this.start > this.min) {
                    return this.calculateAngle(this.start);
                }
                return 0;
            },
            calculateAngle(value) {
                if (value) {
                    const percent = parseInt(((value - this.min) * 100) / (this.max - this.min));
                    return (percent * 300) / 100;
                }
                return 0;
            }
        },
        mounted() {
            this.slider = new CircleSlider('#circle-slider', {
                clockwise: true,
                startOffset: 120,
                minAngle: this.getMinAngle(),
                maxAngle: 300,
            });
            this.slider.on("sliderMove", (angle) => {
                this.angleChanged(angle);
            });

            this.slider.setAngle(this.getDefaultAngle());
        },
    }
</script>
<template>
    <div>
        <div id="circle-slider">
            <slot name="center"></slot>
        </div>
    </div>
</template>