<script>
    export default {
        name: 'rates-toolbar',
        extends: require('../mixins/rates').default
    }
</script>
<template>
    <div>
        <ul class="header__toolbar-rates">
            <li v-for="pair in pairs.slice(0, 6)">
                <a :href="'/trading/' + pair.pair_code">
                    <strong>{{pair.base_code}}/{{pair.quote_code}}</strong>
                    {{getRate(pair.pair_code) | currency(pair.base_code, true)}}
                </a>
            </li>
        </ul>
        <div class="header__rates-menu">
            <span id="ratesDropdown" class="icon-dots-animate" data-toggle="dropdown" aria-expanded="false">
                <span class="icon-dots-animate__dot"></span>
                <span class="icon-dots-animate__dot"></span>
                <span class="icon-dots-animate__dot"></span>
                <span class="icon-dots-animate__dot"></span>
                <span class="icon-dots-animate__dot"></span>
            </span>
            <ul class="dropdown-menu" aria-labelledby="ratesDropdown">
                <li class="dropdown-menu__item" v-for="pair in pairs.slice(6)">
                    <a :href="'/trading/' + pair.pair_code">
                        <strong>{{pair.base_code}}/{{pair.quote_code}}</strong>
                        {{getRate(pair.pair_code) | currency(pair.base_code, true)}}
                    </a>
                </li>
            </ul>
        </div>
    </div>
</template>