import $ from 'jquery';
import 'intl-tel-input';
import 'intl-tel-input/build/js/utils';

export default {
    'inserted': function (element) {
        $(element).intlTelInput({
            autoHideDialCode: false,
            nationalMode: false,
        });
    }
};
