import $ from 'jquery';
import 'card/dist/card';

if ($('#paydoo_form').length) {
    const card = new Card({
        form: '#paydoo_form',
        container: '.card-wrapper',
        formSelectors: {
            nameInput: 'input[name="holder"]',
            numberInput: 'input[name="num"]',
            expiryInput: 'input[name="expirationMonth"], input[name="expirationYear"]',
            cvcInput: 'input[name="cvv"]'
        }
    });

    const func = card.handlers.setCardType;
    card.handlers.setCardType = function () {
        const type = func.apply(this, arguments);
        cardTypeCallback(type);
    };
}

const allowedTypes = ['unknown', 'visa', 'visaelectron', 'mastercard', 'maestro'];
function cardTypeCallback(type) {
    const error = $('input[name=num]').parent().find('.payment-form__error_static');
    if (allowedTypes.includes(type)) {
        error.text('').fadeOut();
    } else {
        error.text('Only VISA, MasterCard or Maestro accepted').fadeIn();
    }
}

$(document).ready(function () {
    $('.payment-form__error').fadeOut();
    $('#paydoo_form').find('input, select, textarea')
        .on('change keypress', function () {
            $(this).parent().removeClass('payment-form__input_error');
            $(this).parent().find('.payment-form__error').fadeOut();
        });
    $('#paydoo_form').submit(function () {
        clearErrors();
        var data = {};
        $.each($(this).serializeArray(), function (_, v) {
            if (data.hasOwnProperty(v.name)) {
                data[v.name] = $.makeArray(data[v.name]);
                data[v.name].push(v.value.trim());
            } else {
                data[v.name] = v.value.trim();
            }
        });
        var hasErrors = false;
        $.each(data, function (k, v) {
            if (v == '') {
                addError(k, translation.field_not_empty);
                hasErrors = true;
            }
            if (k == 'num' && v != '') {
                data[k] = v.replace(/[^0-9.]/g, "");
                if (data[k].length < 13 || data[k].length > 19) {
                    addError(k, translation.card_is_invalid);
                    hasErrors = true;
                }
            }
        });
        if (!hasErrors) {
            $('#paydoo_form').addClass('loading');
            $('#paydoo_form [type="submit"]').prop('disabled', true);
            $.post('/paydoo.php?v=' + getUrlParameter('v') + '&lang=' + userLanguage, data, function (r) {
                $('#paydoo_form').removeClass('loading');
                if (r.result) {
                    $("#paydoo_form")[0].reset();
                    if (r.url) {
                        if (r.redirectParams) {
                            var newForm = $('<form>', {
                                'action': r.url,
                                //'target': '_top',
                                'method': 'POST'
                            });
                            $.each(r.redirectParams, function (key, val) {
                                newForm.append($('<input>', {
                                    'name': key,
                                    'value': val,
                                    'type': 'hidden'
                                }));
                            });
                            newForm.hide().appendTo("body").submit();
                        } else {
                            window.location = r.url;
                        }
                    } else {
                        window.location = '/paydooSuccess?lang=' + userLanguage;
                    }
                } else {
                    $('#paydoo_form [type="submit"]').prop('disabled', false);
                    $.each(r.errors, function (k, v) {
                        addError(k, v);
                    });
                }
            }, 'json').fail(function () {
                $('#paydoo_form').removeClass('loading');
                $('#paydoo_form [type="submit"]').prop('disabled', false);
                addError('system', translation.system_error);
            });
        }
        return false;
    });

    function addError(name, text) {
        if (name == 'system') {
            $('.payment-form__error_global').html(text).fadeIn();
            return;
        }
        var obj = $('[name="' + name + '"]');
        if (!obj.length || obj.is('[type=hidden]')) {
            return;
        }
        var parent = $(obj).parent();
        $(parent).addClass('payment-form__input_error');
        $(parent).find('.payment-form__error').html(text).fadeIn();
    }

    function clearErrors() {
        $('.payment-form__input').removeClass('payment-form__input_error');
        $('.payment-form__error').fadeOut();
    }

    function getUrlParameter(sParam) {
        var sPageURL = decodeURIComponent(window.location.search.substring(1)),
            sURLVariables = sPageURL.split('&'),
            sParameterName,
            i;

        for (i = 0; i < sURLVariables.length; i++) {
            sParameterName = sURLVariables[i].split('=');

            if (sParameterName[0] === sParam) {

                return sParameterName[1] === undefined ? true : sParameterName[1];
            }
        }

        return '';
    }
});